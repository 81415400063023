import { assert } from "@faro-lotv/foundation";
import { GUID } from "@faro-lotv/ielement-types";
import { BackgroundTaskState } from "@faro-lotv/service-wires";
import { uniqueId } from "lodash";
import pLimit from "p-limit";
import { UploadController } from "@context-providers/file-upload/upload-controller";
import {
  FileUploadParams,
  RemoveTaskFn,
  StartUploadFn,
  UpdateTaskFn,
  UpdateTaskProps,
  UploadManagerInterface,
  DEFAULT_CHUNK_SIZE,
  MAX_CONCURRENT_UPLOADS,
  MINIMUM_CHUNK_SIZE,
} from "@custom-types/upload-manager-types";
import { CoreFileUploader, UploadEndpoint } from "@utils/core-file-uploader";

// We batch the task updates within this time period [msec] to avoid too many re-renderings.
export const TASK_UPDATE_DEBOUNCE_DELAY = 1_000;

/**
 * A manager that handles all ongoing uploads.
 *
 * WARNING: this class should not be used directly, it is just exported
 * as an implementation detail to realize the FileUploadContextProvider,
 * and the hooks useFileUpload and useCancelUpload.
 */
export class UploadManager implements UploadManagerInterface {
  #uploads = new Map<GUID, UploadController>();
  #pendingTaskUpdates = new Map<GUID, UpdateTaskProps>();
  #limiter = pLimit(MAX_CONCURRENT_UPLOADS);
  #isManualConcurrency = false;
  #chunkSize = DEFAULT_CHUNK_SIZE;

  /**
   * @param startTaskInStore Function to start a background task in the store
   * @param updateTaskInStore Function to update the task in the store corresponding to the upload
   * @param removeTaskFromStore Function to remove a task from the store.
   */
  constructor(
    public startTaskInStore: StartUploadFn,
    public updateTaskInStore: UpdateTaskFn,
    public removeTaskFromStore: RemoveTaskFn
  ) {
    this.uploadCompleted = this.uploadCompleted.bind(this);
    this.uploadFailed = this.uploadFailed.bind(this);
    this.uploadUpdated = this.uploadUpdated.bind(this);
  }

  /**
   * Used to remove all listeners and upload process from the class
   *
   * @param id ID of the upload to remove
   */
  #removeUpload(id: GUID): void {
    const uploadController = this.#uploads.get(id);
    if (!uploadController) {
      return;
    }

    uploadController.uploadCompleted.off(this.uploadCompleted);
    uploadController.uploadFailed.off(this.uploadFailed);
    uploadController.uploadUpdated.off(this.uploadUpdated);
    this.#uploads.delete(id);
    // Make sure to not apply any obsolete updates to the store.
    this.#pendingTaskUpdates.delete(id);
  }

  /**
   * Callback function triggered when an upload is completed
   * Marks upload task as succeeded
   * Removes the listener and process for the upload
   *
   * @param arg the arguments
   * @param arg.id ID of the upload that completed
   * @param arg.downloadUrl URL containing the uploaded file at the remote location.
   */
  private uploadCompleted(arg: { id: GUID; downloadUrl: string }): void {
    this.updateTaskInStore(arg.id, { status: BackgroundTaskState.succeeded });
    this.#removeUpload(arg.id);
  }

  /**
   * Callback function triggered when an upload is failed
   * Marks upload task as failed
   * Removes the listener and process for the upload
   *
   * @param arg the arguments
   * @param arg.id ID of the upload that failed
   * @param arg.error Error thrown that made the upload to fail.
   */
  private uploadFailed(arg: { id: GUID; error: Error }): void {
    this.updateTaskInStore(arg.id, {
      status: BackgroundTaskState.failed,
      errorMessage: arg.error.message,
    });
    this.#removeUpload(arg.id);
  }

  /**
   * Callback function triggered when an upload got progress
   *
   * @param arg the arguments
   * @param arg.id ID of the upload that failed
   * @param arg.progress Current progress of the given upload, from 0 to 100
   * @param arg.expectedEnd Expected end timestamp of this task
   */
  private uploadUpdated(arg: {
    id: GUID;
    progress: number;
    expectedEnd: number;
    speedMBps: number;
  }): void {
    // If there were already pending updates, there is already a scheduled timeout.
    if (this.#pendingTaskUpdates.size === 0) {
      setTimeout(() => { this.bulkUpdateTasksInStore(); }, TASK_UPDATE_DEBOUNCE_DELAY);
    }

    this.#pendingTaskUpdates.set(arg.id, {
      progress: arg.progress,
      status: BackgroundTaskState.started,
      expectedEnd: arg.expectedEnd,
      speedMBps: arg.speedMBps,
    });
  }

  /** Apply several task updates in the store at once to avoid too many re-renderings. */
  private bulkUpdateTasksInStore(): void {
    for (const [id, props] of this.#pendingTaskUpdates) {
      this.updateTaskInStore(id, props);
    }
    this.#pendingTaskUpdates.clear();
  }

  /**
   * Starts a new file upload and adds it to the managed uploads.
   */
  startFileUpload({
    file,
    isSilent = false,
    coreApiClient,
    finalizer,
    onUploadCompleted,
    onUploadFailed,
    onUploadUpdated,
    onUploadCanceled,
    context,
  }: FileUploadParams): void {
    const id = uniqueId();
    const controller = new UploadController(
      id,
      file,
      // The CoreAPI endpoint to upload files always requires the project ID field.
      // If the project ID is not defined then just pass an empty string.
      context.projectId ?? "",
      coreApiClient,
      this.#chunkSize
    );

    if (onUploadCompleted) {
      controller.uploadCompleted.on(
        (arg: { id: GUID; downloadUrl: string; md5: string }) =>
          onUploadCompleted(
            arg.id,
            file.name,
            file.size,
            file.type,
            arg.downloadUrl,
            arg.md5
          )
      );
    }
    if (onUploadFailed) {
      controller.uploadFailed.on(
        (arg: { id: GUID; fileName: string; error: Error }) =>
          onUploadFailed(arg.id, arg.fileName, arg.error)
      );
    }
    if (onUploadUpdated) {
      controller.uploadUpdated.on((arg: { id: GUID; progress: number }) =>
        onUploadUpdated(arg.id, arg.progress)
      );
    }
    if (onUploadCanceled) {
      controller.uploadCanceled.on((arg: { id: GUID; fileName: string }) =>
        onUploadCanceled(arg.id, arg.fileName)
      );
    }
    controller.uploadCompleted.on(this.uploadCompleted);
    controller.uploadFailed.on(this.uploadFailed);
    controller.uploadUpdated.on(this.uploadUpdated);
    this.#uploads.set(id, controller);
    // Update the store with the new task
    this.startTaskInStore(id, file, isSilent, context);
    // Start the actual upload, once there is a free slot, according to the limiter.
    void this.#limiter(() => {
      return controller.uploader.doUpload(id, controller.abortController.signal, finalizer);
    });
  }

  /**
   * Cancels a file upload
   *
   * @param id The ID of the corresponding background task in the store
   * @param shouldRemoveTaskFromStore True to remove the task from store, primarily when all uploads are aborted.
   * @returns Whether the upload existed and was in progress, therefore canceled correctly.
   */
  cancelFileUpload(id: GUID, shouldRemoveTaskFromStore: boolean = false): boolean {
    const controller = this.#uploads.get(id);
    if (shouldRemoveTaskFromStore) {
      this.removeTaskFromStore(id);
    }
    // created:   If the upload is delayed by the limiter, we should still allow to cancel it.
    // scheduled: Seems currently not used for upload tasks; added "just in case".
    const ALLOWED_STATES_TO_CANCEL = [
      BackgroundTaskState.created,
      BackgroundTaskState.scheduled,
      BackgroundTaskState.started,
    ];
    const state = controller?.uploader?.state;
    if (!controller || !state || !ALLOWED_STATES_TO_CANCEL.includes(state)) {
      return false;
    }
    controller.abortController.abort();
    this.updateTaskInStore(id, { status: BackgroundTaskState.aborted });
    this.#uploads.delete(id);
    // Make sure to not apply any obsolete updates to the store.
    this.#pendingTaskUpdates.delete(id);
    return true;
  }

  /**
   * Sets the maximum number of concurrent file uploads.
   * @param files Max concurrent files (integer >= 1).
   * @param chunks Max concurrent chunks per file (integer >= 1).
   * @param isManual True if manually setting the concurrency. This will prevent automatic adjustements.
   */
  setMaxConcurrentUploads(files: number, chunks: number, isManual: boolean): void {
    assert(
      Number.isInteger(files) && files >= 1,
      "The maximum number of concurrent file uploads must be an integer >= 1."
    );
    assert(
      Number.isInteger(chunks) && chunks >= 1,
      "The maximum number of concurrent chunk uploads per file must be an integer >= 1."
    );
    this.#limiter.concurrency = files;
    CoreFileUploader.setMaxConcurrentChunks(chunks, [...this.#uploads.values()].map((c) => c.uploader));
    this.#isManualConcurrency = isManual;
  }

  /**
   * Automatically sets the maximum number of concurrent file uploads for the given files.
   * @param files Files to upload.
   */
  setMaxConcurrentUploadsAuto(files: File[]): void {
    const hasFilesPendingUploading = [...this.#uploads.values()]
      .map((c) => c.uploader)
      .some((u) => u.state === BackgroundTaskState.created || u.state === BackgroundTaskState.started);

    // If there are already uploads in progress, it's better to not adjust the limits, otherwise it would be tricky
    // to avoid exceeding our expected total number of parallel chunks.
    if (this.#isManualConcurrency || hasFilesPendingUploading || !files.length) {
      return;
    }

    /* eslint-disable @typescript-eslint/no-magic-numbers -- The code won't get much better by introducing more constants. */
    const avgSize = files.reduce((sum, f) => sum + f.size, 0) / files.length;
    if (avgSize >= 4 * this.#chunkSize) {
      this.setMaxConcurrentUploads(4, 4, false);
    } else if (avgSize >= 2 * this.#chunkSize) {
      this.setMaxConcurrentUploads(8, 2, false);
    } else {
      this.setMaxConcurrentUploads(16, 1, false);
    }
    /* eslint-enable @typescript-eslint/no-magic-numbers */
  }


  /**
   * Set the chunk size for chunked upload in bytes.
   * @param value Integer > 1_048_576
   */
  setChunkSize(value: number): void {
    assert(
      Number.isInteger(value) && value >= MINIMUM_CHUNK_SIZE,
      `The chunk size must be an integer >= ${MINIMUM_CHUNK_SIZE}}.`
    );
    this.#chunkSize = value;
  }

  /** Set the upload endpoint to use. */
  setUploadEndpoint(endpoint: UploadEndpoint): void {
    assert(endpoint === "auto" || endpoint === "frontdoor" || endpoint === "storage");
    CoreFileUploader.setUploadEndpoint(endpoint);
  }

  /**
   * This is not a shared worker
   * @returns true
   */
  isSharedWorker(): boolean {
    return false;
  }
}
