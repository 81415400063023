import { useEffect, useMemo } from "react";
import { useAppDispatch, useAppSelector } from "@store/store-helper";
import {
  displayProjectsSelector,
  fetchingProjectsFlagsSelector,
  hasLoadedProjectsSelector,
  nextProjectSelector,
} from "@store/projects/projects-selector";
import { increaseNumberOfDisplayedProjects } from "@store/projects/projects-slice";
import { useCoreApiClient } from "@api/use-core-api-client";
import { ProjectsPageTable } from "@pages/projects/projects-page-table";
import { dataViewModeSelector, searchSelector } from "@store/ui/ui-selector";
import { DataViewMode } from "@store/ui/ui-slice";
import { NotFoundPage } from "@pages/not-found-page";
import { ProjectArchivingState } from "@custom-types/project-types";
import { useAppParams } from "@router/router-helper";
import { ProjectsTiles } from "@pages/projects/projects-tiles";
import { ProjectsOverviewEmptyPage } from "@components/common/empty-page/projects-overview-empty-page";
import { ButtonEvents } from "@utils/track-event/track-event-list";
import { useTrackEvent } from "@utils/track-event/use-track-event";
import {
  fetchProjects,
  fetchSearchedProjects,
} from "@store/projects/projects-slice-thunk";
import { MIN_CHARACTERS_FOR_SEARCH } from "@utils/project-utils";

interface Props {
  /** The archiving-state of the projects that will be shown */
  projectArchivingState: ProjectArchivingState;

  /**
   * Flag whether the content should be shown as loading regardless whether it was loaded.
   * This is useful when the parent component know that the content should be loading,
   * e.g. it is still loading the permissions.
   */
  shouldForceLoading?: boolean;
}

/**
 * The projects tab listing all the active or archived projects (depending on the props) in the company
 */
export function ProjectsOverview({
  projectArchivingState,
  shouldForceLoading = false,
}: Props): JSX.Element {
  const { companyId } = useAppParams();
  const coreApiClient = useCoreApiClient();
  const dispatch = useAppDispatch();
  const { trackEvent } = useTrackEvent();
  const { debouncedSearchText, isDebouncingSearch } = useAppSelector(searchSelector);
  const { projectsView } = useAppSelector(dataViewModeSelector);
  const { isFetchingProjects } = useAppSelector(fetchingProjectsFlagsSelector);
  const hasLoadedProjects = useAppSelector(
    hasLoadedProjectsSelector(projectArchivingState)
  );
  const displayProjects = useAppSelector(
    displayProjectsSelector(projectArchivingState)
  );

  /** Stores the id of the next project to be fetched */
  const nextProject = useAppSelector(nextProjectSelector);

  /** Computes whether it is still loading to fetch all projects */
  const isLoading = useMemo(() => {
    return (
      isFetchingProjects ||
      isDebouncingSearch ||
      shouldForceLoading ||
      !hasLoadedProjects
    );
  }, [
    isFetchingProjects,
    isDebouncingSearch,
    shouldForceLoading,
    hasLoadedProjects,
  ]);

  /** Flag whether the empty page should be shown, because it is no longer loading and does not have projects */
  const shouldShowEmptyPage = useMemo(() => {
    return !isLoading && hasLoadedProjects && displayProjects.length === 0;
  }, [isLoading, hasLoadedProjects, displayProjects]);

  /**
   * Flag whether the load more projects button should be hidden, either because there are no more projects to fetch,
   * or because the user is searching for projects.
   */
  const shouldHideLoadMoreButton = useMemo(() => {
    return !nextProject || !!debouncedSearchText;
  }, [debouncedSearchText, nextProject]);

  // Fetches the searched projects
  // Only request the searched projects if the search char length is at least the minimum required by the backend.
  useEffect(() => {
    async function getSearchedProjects(): Promise<void> {
      if (companyId) {
        await dispatch(
          fetchSearchedProjects({
            coreApiClient,
            projectArchivingState,
            companyId,
            searchText: debouncedSearchText,
          })
        );
      }
    }
    if (debouncedSearchText.length >= MIN_CHARACTERS_FOR_SEARCH) {
      void getSearchedProjects();
    }
  }, [
    companyId,
    coreApiClient,
    dispatch,
    projectArchivingState,
    debouncedSearchText,
  ]);

  /**
   * Calls the backend to fetch more projects and increases the number of projects shown on the screen.
   */
  async function loadMoreProjects(): Promise<void> {
    if (companyId && nextProject) {
      trackEvent({
        name: ButtonEvents.loadMore,
        props: {
          data: `${projectArchivingState} projects`,
        },
      });
      await dispatch(
        fetchProjects({
          coreApiClient,
          companyId,
          projectArchivingState,
          next: nextProject,
        })
      );
      dispatch(increaseNumberOfDisplayedProjects());
    }
  }

  if (!companyId) {
    return <NotFoundPage />;
  }

  // If fetching is done and displayProjects is empty, then
  // either no project with selected archive state exist or no project with the searchText is available
  if (shouldShowEmptyPage) {
    return (
      <ProjectsOverviewEmptyPage
        projectArchivingState={projectArchivingState}
      />
    );
  }

  if (projectsView === DataViewMode.list) {
    return (
        <ProjectsPageTable
          projects={displayProjects}
          companyId={companyId}
          projectStatus={projectArchivingState}
          isLoading={isLoading}
          shouldHideLoadMoreButton={shouldHideLoadMoreButton}
          loadMoreProjects={loadMoreProjects}
        />
    );
  } else {
    return (
      <ProjectsTiles
        projects={displayProjects}
        companyId={companyId}
        isLoading={isLoading}
        shouldHideLoadMoreButton={shouldHideLoadMoreButton}
        loadMoreProjects={loadMoreProjects}
        projectStatus={projectArchivingState}
      />
    );
  }
}
