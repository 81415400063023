import { Box, Typography } from "@mui/material";
import { CommonStyles } from "@styles/common-styles";
import { Alert } from "@faro-lotv/flat-ui";

/**
 * Displays an alert to notify the user that their credits are expiring soon.
 */
export function CreditExpirationAlert(): JSX.Element {
  return (
    <Box
      sx={{
        borderBottom: CommonStyles.Borders.gray200Divider,
        borderTop: CommonStyles.Borders.gray200Divider,
        width: "100%",
      }}
    >
     <Alert
        title={
          <Typography
            sx={{
              fontSize: "12px",
              fontWeight: 700,
            }}
          >
            Your credits are expiring soon! Use them before they expire, or you'll lose them.
          </Typography>}
        variant="warning"
        />
    </Box>
  );
}