// Use Grid version 2, because it will be the stable version in the newer MUI versions
import Grid from "@mui/material/Unstable_Grid2";
import { sphereColors } from "@styles/common-colors";
import { HEADER_HEIGHT } from "@utils/ui-utils";
import { HeaderLayoutRight } from "@components/page-layout/header/header-layout-right";
import { HeaderLayoutLeft } from "@components/page-layout/header/header-layout-left";
import { HeaderLayoutCenter } from "@components/page-layout/header/header-layout-center";
import { MainPageLayoutProps } from "@components/page-layout/main-page-layout";

type Props = Pick<
  MainPageLayoutProps,
  | "shouldShowLogo"
  | "topLeftHeaderButton"
  | "topbarBackgroundColor"
  | "shouldShowTopbarBorder"
  | "shouldHideOpenButton"
  | "shouldShowSidebar"
  | "titleComponent"
  | "shouldShowProgressOverviewMenu"
  | "shouldShowUserMenu"
>;

/** Content of the app header */
export function AppHeader({
  shouldShowLogo = false,
  topLeftHeaderButton,
  topbarBackgroundColor = sphereColors.pureWhite,
  shouldShowTopbarBorder,
  shouldHideOpenButton = false,
  shouldShowSidebar = true,
  titleComponent,
  shouldShowProgressOverviewMenu = true,
  shouldShowUserMenu = true,
}: Props): JSX.Element {
  return (
    <Grid
      container
      sx={{
        // The logo needs a smaller padding on the left side
        pl: shouldShowLogo ? "0.5rem" : "1.125rem",
        pr: "0.5rem",
        py: topLeftHeaderButton ? "0" : "0.375rem",
        maxHeight: "7.5rem",
        minHeight: HEADER_HEIGHT,
        backgroundColor: topbarBackgroundColor,
        borderBottom: shouldShowTopbarBorder
          ? `1px solid ${sphereColors.gray200}`
          : "",
      }}
      data-testid="HEADER_CONTAINER"
      alignItems="center"
      justifyContent="space-between"
      flexWrap={topLeftHeaderButton ? "nowrap" : "wrap"}
      width="100%"
      position={"sticky"}
      top={0}
      zIndex={1}
    >
      {/* Left side of the top-bar */}
      <HeaderLayoutLeft
        shouldShowLogo={shouldShowLogo}
        shouldHideOpenButton={shouldHideOpenButton}
        topLeftHeaderButton={topLeftHeaderButton}
        shouldShowSidebar={shouldShowSidebar}
      />

      {/* Center of the top-bar */}
      <HeaderLayoutCenter titleComponent={titleComponent} />

      {/* Right side of the top-bar */}
      <HeaderLayoutRight
        shouldShowProgressOverviewMenu={shouldShowProgressOverviewMenu}
        shouldShowUserMenu={shouldShowUserMenu}
      />
    </Grid>
  );
}
