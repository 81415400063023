import {
  CaptureTreeEntityRevision,
  CaptureTreeEntityType,
  CaptureTreePointCloudType,
  RevisionStatus,
} from "@faro-lotv/service-wires";

export const MOCK_CAPTURE_TREE_FOR_REVISION: CaptureTreeEntityRevision[] = [
  {
    id: "57f614e0-47c8-4c61-41d5-08dcbbbee96f",
    parentId: null,
    name: "Root",
    pose: {
      pos: {
        x: 0,
        y: 0,
        z: 0,
      },
      scale: {
        x: 1,
        y: 1,
        z: 1,
      },
      rot: {
        x: 0,
        y: 0,
        z: 0,
        w: 1,
      },
    },
    type: CaptureTreeEntityType.root,
    createdAt: "2024-08-13T17:40:07.8261263+00:00",
    createdBy: "49ecc9e8-548b-4910-a08b-f86c720bf254",
    lastPatchedAt: "2024-08-13T17:40:07.8261263+00:00",
    lastPatchedBy: "49ecc9e8-548b-4910-a08b-f86c720bf254",
    status: RevisionStatus.added,
  },
  {
    id: "49ab8717-81e4-450f-7a66-08dcbbbef93e",
    parentId: "57f614e0-47c8-4c61-41d5-08dcbbbee96f",
    name: "Blink Scans_08/13/2024",
    pose: {
      pos: {
        x: 0,
        y: 0,
        z: 0,
      },
      scale: {
        x: 1,
        y: 1,
        z: 1,
      },
      rot: {
        x: 0,
        y: 0,
        z: 0,
        w: 1,
      },
    },
    type: CaptureTreeEntityType.cluster,
    createdAt: "2024-08-13T17:40:08.2600185+00:00",
    createdBy: "49ecc9e8-548b-4910-a08b-f86c720bf254",
    lastPatchedAt: "2024-08-13T17:40:08.2600185+00:00",
    lastPatchedBy: "49ecc9e8-548b-4910-a08b-f86c720bf254",
    status: RevisionStatus.added,
  },
  {
    id: "9e4b1ef8-0447-490f-ac8e-267389693af5",
    parentId: "49ab8717-81e4-450f-7a66-08dcbbbef93e",
    name: "Sub cluster",
    pose: {
      pos: {
        x: 0,
        y: 0,
        z: 0,
      },
      scale: {
        x: 1,
        y: 1,
        z: 1,
      },
      rot: {
        x: 0,
        y: 0,
        z: 0,
        w: 1,
      },
    },
    type: CaptureTreeEntityType.cluster,
    createdAt: "2024-08-14T17:40:08.2600185+00:00",
    createdBy: "49ecc9e8-548b-4910-a08b-f86c720bf254",
    lastPatchedAt: "2024-08-14T17:40:08.2600185+00:00",
    lastPatchedBy: "49ecc9e8-548b-4910-a08b-f86c720bf254",
    status: RevisionStatus.added,
  },
  {
    pointClouds: [
      {
        externalId: "86248c43-8b3f-4698-9624-8cb516cfcf3e",
        type: CaptureTreePointCloudType.elsRaw,
        id: "16ab52e2-e554-469b-91d7-08dcbaf989b4",
        uri: "someUri",
        md5Hash: "8a44cfce9b9cb7842c57ef8c7d292992",
        fileSize: 98230272,
        fileName: "adbf1a9a-7773-4a62-910b-26689fc1ebc3.gls",
      },
    ],
    id: "18395440-89bc-4cb6-067b-08dcbaf96bb3",
    parentId: "49ab8717-81e4-450f-7a66-08dcbbbef93e",
    name: "kitchen_01",
    pose: {
      pos: {
        x: 0,
        y: 0,
        z: 0,
      },
      scale: {
        x: 1,
        y: 1,
        z: 1,
      },
      rot: {
        x: 0,
        y: 0,
        z: 0,
        w: 1,
      },
    },
    type: CaptureTreeEntityType.elsScan,
    createdAt: "2024-08-12T18:06:50.0491412+00:00",
    createdBy: "49ecc9e8-548b-4910-a08b-f86c720bf254",
    lastPatchedAt: "2024-08-12T18:06:50.0491426+00:00",
    lastPatchedBy: "49ecc9e8-548b-4910-a08b-f86c720bf254",
    status: RevisionStatus.added,
  },
  {
    pointClouds: [
      {
        externalId: "25c066de-6fe5-45ea-bc1e-e71535ba6e37",
        type: CaptureTreePointCloudType.flsRaw,
        id: "41753e94-6770-4fd5-8491-623873e24160",
        uri: "someUri",
        md5Hash: "2b44cfce9b9cb7842c57ef8c7d292992",
        fileSize: 34230272,
        fileName: "a8a80e14-a16e-4b48-a5f8-f16dd9825124.fls",
      },
    ],
    id: "f7a0cd48-a5f9-4d54-b43c-2fc36c4b74c0",
    parentId: "49ab8717-81e4-450f-7a66-08dcbbbef93e",
    name: "dataset_01",
    pose: {
      pos: {
        x: 0,
        y: 0,
        z: 0,
      },
      scale: {
        x: 1,
        y: 1,
        z: 1,
      },
      rot: {
        x: 0,
        y: 0,
        z: 0,
        w: 1,
      },
    },
    type: CaptureTreeEntityType.focusScan,
    createdAt: "2024-08-12T18:06:50.0491412+00:00",
    createdBy: "49ecc9e8-548b-4910-a08b-f86c720bf254",
    lastPatchedAt: "2024-08-12T18:06:50.0491426+00:00",
    lastPatchedBy: "49ecc9e8-548b-4910-a08b-f86c720bf254",
    status: RevisionStatus.added,
  },
  {
    pointClouds: [
      {
        externalId: "a23e223c-4dd3-4482-aea9-ae7e6e9a52fd",
        type: CaptureTreePointCloudType.flsRaw,
        id: "d897bb8c-088d-4bf0-8fd9-683a7429fd5c",
        uri: "someUri",
        md5Hash: "3a44cfce9b9cb7842c57ef8c7d292992",
        fileSize: 7230272,
        fileName: "scan01.e57",
      },
    ],
    id: "b264b498-b208-4825-b18f-c06c1c8cf8b7",
    parentId: "49ab8717-81e4-450f-7a66-08dcbbbef93e",
    name: "scan01",
    pose: {
      pos: {
        x: 0,
        y: 0,
        z: 0,
      },
      scale: {
        x: 1,
        y: 1,
        z: 1,
      },
      rot: {
        x: 0,
        y: 0,
        z: 0,
        w: 1,
      },
    },
    type: CaptureTreeEntityType.pCloudUploadScan,
    createdAt: "2024-08-12T18:06:50.0491412+00:00",
    createdBy: "49ecc9e8-548b-4910-a08b-f86c720bf254",
    lastPatchedAt: "2024-08-12T18:06:50.0491426+00:00",
    lastPatchedBy: "49ecc9e8-548b-4910-a08b-f86c720bf254",
    status: RevisionStatus.added,
  },
];
