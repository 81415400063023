import { isCloudRegistrationTask } from "@custom-types/sdb-background-tasks-type-guards";
import { RegisteredData } from "@pages/project-details/project-data-management/registered-data/registered-data-types";
import { createSelector } from "@reduxjs/toolkit";
import { allCaptureTreeRevisionsSelector } from "@store/capture-tree/capture-tree-selectors";
import { sdbBackgroundTasksSelector } from "@store/sdb-background-tasks/sdb-background-tasks-selector";
import { RootState } from "@store/store-helper";
import {
  hasRegisterError,
  hasBadRegistration,
  isRegistered,
  isRegistering,
  isRegisteringOrRegistered,
} from "@pages/project-details/project-data-management/registered-data/registered-data-utils";
import { CaptureApiClient } from "@faro-lotv/service-wires";

/**
 * @returns All RegisteredData revisions currently getting registered or already registered or failed to register.
 *          Sorted from newest creation date to oldest.
 */
export const registeredDataSelector: (state: RootState) => RegisteredData[] =
  createSelector(
    (state: RootState) => state,
    (state: RootState) => {
      // TF-1908: All revisions are "registration revisions" by design, but in reality, for actual
      // registered data we only care about the ones from the "RegistrationBackend" client.
      const registrations = allCaptureTreeRevisionsSelector(state).filter(
        (registration) => registration.createdByClient === CaptureApiClient.registrationBackend
      );
      const backgroundTasks = sdbBackgroundTasksSelector(state);
      const cloudRegistrationTasks = backgroundTasks.filter(isCloudRegistrationTask);

      const registrationsWithTask: RegisteredData[] = registrations.map((registration) => {
        const task = cloudRegistrationTasks.find(
          (cloudRegistrationTask) =>
            cloudRegistrationTask.context?.elementId === registration.id
        );

        return {
          ...registration,
          task,
        };
      });

      // Filter out canceled revisions.
      return registrationsWithTask
        .filter(isRegisteringOrRegistered)
        .sort((a, b) => {
          const aCreatedAt = new Date(a.createdAt).getTime();
          const bCreatedAt = new Date(b.createdAt).getTime();
          return bCreatedAt - aCreatedAt;
        });
    }
  );

/**
 * @returns true if the newest RegisteredData revision is getting registered.
 */
export const isRegisteringSelector: (state: RootState) => boolean =
  createSelector(
    (state: RootState) => state,
    (state: RootState) => {
      const revisions = registeredDataSelector(state);
      return 0 < revisions.length && isRegistering(revisions[0]);
    }
  );

/**
 * @returns true if the newest RegisteredData revision has been registered.
 */
export const isRegisteredSelector: (state: RootState) => boolean =
  createSelector(
    (state: RootState) => state,
    (state: RootState) => {
      const revisions = registeredDataSelector(state);
      return 0 < revisions.length && isRegistered(revisions[0]);
    }
  );

/**
 * @returns an object of two booleans depending if the RegisteredData revision has a task that failed to finish
 * or has succeeded but has bad registration.
 */
export const hasRegisterErrorSelector: (state: RootState) => { hasRegisterError: boolean; hasBadRegistration: boolean } =
  createSelector(
    (state: RootState) => state,
    (state: RootState) => {
      const revisions = registeredDataSelector(state);
      const hasRevisions = revisions.length > 0;
      return {
        hasRegisterError: hasRevisions && hasRegisterError(revisions[0]),
        hasBadRegistration: hasRevisions && hasBadRegistration(revisions[0]),
      };
    }
  );
