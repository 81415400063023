import { FileUploadTask } from "@custom-types/file-upload-types";
import { Box, LinearProgress, Stack, Typography } from "@mui/material";
import { sphereColors } from "@styles/common-colors";
import FileIcon from "@assets/icons/new/file_50px.svg?react";
import CancelIcon from "@assets/icons/Cancel.svg?react";
import { FaroIconButton } from "@components/common/faro-icon-button";
import { DEFAULT_TEXT_FONT_SIZE, withEllipsis } from "@styles/common-styles";
import { BackgroundTaskState } from "@faro-lotv/service-wires";
import { useCancelUpload } from "@hooks/upload-tasks/use-cancel-upload";
import { useAppDispatch } from "@store/store-helper";
import { removeOne } from "@store/upload-tasks/upload-tasks-slice";

interface Props {
  /** The upload task to display the upload progress */
  task: FileUploadTask;

  /** True if the current element is the last one in the list */
  isLastElement: boolean;
}

/** The class of the cancel button */
const cancelButtonClass = "cancelButton";

/** The class of the progress bar */
const progressBarClass = "progressBar";

/** The class of the file name */
const fileNameClass = "fileName";

/** Renders a markup attachment upload task */
export function MarkupUploadingAttachmentItem({
  task,
  isLastElement,
}: Props): JSX.Element {
  const cancelUploadTask = useCancelUpload();
  const dispatch = useAppDispatch();

  async function cancelUpload(): Promise<void> {
    await cancelUploadTask(task.id);

    if (task.status === BackgroundTaskState.failed) {
      dispatch(removeOne(task.id));
    }
  }

  return (
    <Box
      data-testid="markup-side-panel-attachment-item-container"
      sx={{
        display: "flex",
        gap: "8px",
        borderBottom: !isLastElement ? 1 : 0,
        borderColor: "divider",
        padding: "8px",
        transition: "background-color 0.3s",
        alignItems: "center",
        "&:hover": {
          backgroundColor: sphereColors.gray100,
          cursor: "pointer",
          [`& .${fileNameClass}`]: {
            color: sphereColors.blue500,
          },
          [`& .${cancelButtonClass}`]: {
            display: "block",
          },
          [`& .${progressBarClass}`]: {
            display: "none",
          },
        },
      }}
    >
      {/* File Icon */}
      <Box sx={{ height: "43px", display: "flex", alignItems: "center" }}>
        <FileIcon width="60px" height="32px" />
      </Box>

      <Stack sx={{ gap: 0.5, width: "calc(100% - 110px)" }}>
        {/* File name */}
        <Typography
          className={fileNameClass}
          sx={{
            fontSize: DEFAULT_TEXT_FONT_SIZE,
            color: sphereColors.gray800,
            ...withEllipsis,
          }}
        >
          <var>{task.fileName}</var>
        </Typography>

        {/* Failed message or progress bar */}
        {task.status === BackgroundTaskState.failed ? (
          <Typography
            sx={{
              color: sphereColors.red600,
              fontWeight: "600",
              fontSize: "10px",
              ...withEllipsis,
            }}
          >
            {task.errorMessage ?? "Unexpected error. Please contact support."}
          </Typography>
        ) : (
          <LinearProgress
            value={task.progress}
            variant={task.progress > 0 ? "determinate" : "indeterminate"}
          />
        )}
      </Stack>

      {/* Cancel icon */}
      <Box sx={{ textAlign: "center", width: "50px" }}>
        {task.progress > 0 && task.status !== BackgroundTaskState.failed && (
          <var
            className={progressBarClass}
            style={{
              fontSize: DEFAULT_TEXT_FONT_SIZE,
              color: sphereColors.gray600,
            }}
          >
            {/* Round because upload-worker sends "progress: 0.00001" when
                upload starts, to switch to determinate progress bar. */}
            {Math.round(task.progress)}%
          </var>
        )}

        <Box
          className={cancelButtonClass}
          sx={{
            display:
              task.status === BackgroundTaskState.failed ? "block" : "none",
          }}
        >
          <FaroIconButton
            component={CancelIcon}
            // eslint-disable-next-line @typescript-eslint/no-misused-promises -- Please review lint error
            onClick={cancelUpload}
            iconSize="18px"
          />
        </Box>
      </Box>
    </Box>
  );
}
