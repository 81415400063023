import ArrowLeftIcon from "@assets/icons/new/arrow-left-2_16px.svg?react";
import {
  generateProjectDetailsRoute,
  useAppParams,
} from "@router/router-helper";
import { ProjectTabs } from "@router/route-params";
import { useAppSelector } from "@store/store-helper";
import { selectedProjectSelector } from "@store/projects/projects-selector";
import { DataManagementEvents } from "@utils/track-event/track-event-list";
import { useTrackEvent } from "@utils/track-event/use-track-event";
import { HeaderLayoutLeftButton } from "@components/page-layout/header/header-layout-left-button";

/** Button in the header of the Staging Area to go back to the project overview. */
export function ProjectOverviewButton(): JSX.Element {
  const { trackEvent } = useTrackEvent();
  const { companyId } = useAppParams();
  const project = useAppSelector(selectedProjectSelector);

  /** Tracks closing the Staging Area and showing the project details page again. Navigation is done via "to". */
  function trackShowProjectOverview(): void {
    if (!companyId || !project) {
      return;
    }
    trackEvent({
      name: DataManagementEvents.openProjectOverview,
      props: { projectId: project.id },
    });
  }

  return (
    <HeaderLayoutLeftButton
      tooltip="Back to Project Overview"
      icon={ArrowLeftIcon}
      text="Project Overview"
      onClickHandler={trackShowProjectOverview}
      navigationTo={
        companyId && project
          ? generateProjectDetailsRoute(
              companyId,
              project.id,
              ProjectTabs.overview
            )
          : undefined
      }
    />
  );
}
