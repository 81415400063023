import { ClusterEntity, RootEntity, ScanEntity } from "@custom-types/capture-tree/capture-tree-types";
import {
  CaptureTreeEntityType,
  CaptureTreePointCloudType,
} from "@faro-lotv/service-wires";

/**
 * Example capture tree.
 * All "elsRaw" and "elsProcessed" point clouds (PCs) have an "externalId".
 *
 * [ 0] - RootEntity "Root"
 * [ 1]   - Cluster "Cluster A"
 * [ 2]     - Scan "2024-01-31_12-46-49" (Orbis with PCs: geoslam, laz, e57)
 * [ 3]     - Scan "2024-01-31_13-24-28" (Orbis with PCs: geoslam, laz, e57)
 * [ 4]     - Scan "2024-01-31_12-59-29" (Orbis with PCs: geoslam, laz, e57)
 * [ 5]     - Scan "Merged PC"  (Orbis with PCs: laz)
 * [ 6]     - Scan "test_bilal" (Orbis with PCs: laz)
 * [ 7]     - Scan "dataset_01" (ELS with PCs: elsRaw)
 * [ 8]     - Scan "dataset_02" (ELS with PCs: elsProcessed)
 * [ 9]     - Cluster "Cluster B"
 * [10]       - Scan "dataset_03" (ELS with PCs: elsProcessed, e57)
 */
export const MOCK_CAPTURE_TREE_FOR_MAIN_REVISION: [
  RootEntity, ClusterEntity, ScanEntity, ScanEntity, ScanEntity,
  ScanEntity, ScanEntity, ScanEntity, ScanEntity, ClusterEntity,
  ScanEntity
] = [
  {
    id: "72f45eba-98d7-4e7e-962e-90bd66e0c48d",
    parentId: null,
    name: "Root",
    pose: {
      pos: {
        x: 0.0,
        y: 0.0,
        z: 0.0,
      },
      scale: {
        x: 1.0,
        y: 1.0,
        z: 1.0,
      },
      rot: {
        x: 0.0,
        y: 0.0,
        z: 0.0,
        w: 1.0,
      },
    },
    type: CaptureTreeEntityType.root,
    createdAt: "2024-06-28T12:30:08.2611264+00:00",
    createdBy: "98e4cc79-779b-4f82-a490-6bd72d709b5e",
    lastPatchedAt: "2024-06-28T12:30:08.2611264+00:00",
    lastPatchedBy: "98e4cc79-779b-4f82-a490-6bd72d709b5e",
  },
  {
    id: "609149c3-16f0-4dae-23a6-08dc976e072b",
    parentId: "72f45eba-98d7-4e7e-962e-90bd66e0c48d",
    name: "Cluster A",
    pose: {
      pos: {
        x: 0.0,
        y: 0.0,
        z: 0.0,
      },
      scale: {
        x: 1.0,
        y: 1.0,
        z: 1.0,
      },
      rot: {
        x: 0.0,
        y: 0.0,
        z: 0.0,
        w: 1.0,
      },
    },
    type: CaptureTreeEntityType.cluster,
    createdAt: "2024-06-28T12:30:08.2611264+00:00",
    createdBy: "98e4cc79-779b-4f82-a490-6bd72d709b5e",
    lastPatchedAt: "2024-06-28T12:30:08.2611264+00:00",
    lastPatchedBy: "98e4cc79-779b-4f82-a490-6bd72d709b5e",
  },
  {
    pointClouds: [
      {
        id: "eeaa63ee-63db-488d-a880-08dc57ae84af",
        type: CaptureTreePointCloudType.geoslam,
        uri: "someUrl",
        md5Hash: "339074c4fa49730bca285a40299d74ff",
        fileSize: 1179205632,
        fileName: "322e56aa-413f-49a2-96f7-4e2c387dc66f.geoslam",
      },
      {
        id: "bb3f9804-5af6-4037-f380-08dc57b792c5",
        type: CaptureTreePointCloudType.laz,
        uri: "someUrl",
        md5Hash: "6f4ce852a4661a4ba907c8f52b45bfd6",
        fileSize: 553072592,
        fileName: "2024-01-31_12-46-49.laz",
      },
      {
        id: "2974a81e-1b11-48c8-5780-08dc57b9b3d6",
        type: CaptureTreePointCloudType.e57,
        uri: "someUrl",
        md5Hash: "53f78f3177353bdd2f50d11efa9d881b",
        fileSize: 3370580992,
        fileName: "flash_scan.e57",
      },
    ],
    id: "15192347-3432-499a-a780-08dc57ae84af",
    parentId: "609149c3-16f0-4dae-23a6-08dc976e072b",
    name: "2024-01-31_12-46-49",
    pose: {
      pos: {
        x: 0.0,
        y: 0.0,
        z: 0.0,
      },
      scale: {
        x: 1.0,
        y: 1.0,
        z: 1.0,
      },
      rot: {
        x: 0.0,
        y: 0.0,
        z: 0.0,
        w: 1.0,
      },
    },
    type: CaptureTreeEntityType.orbisScan,
    createdAt: "2024-06-28T12:30:08.2667549+00:00",
    createdBy: "98e4cc79-779b-4f82-a490-6bd72d709b5e",
    lastPatchedAt: "2024-06-28T12:30:08.2667549+00:00",
    lastPatchedBy: "98e4cc79-779b-4f82-a490-6bd72d709b5e",
  },
  {
    pointClouds: [
      {
        id: "109d7424-239e-457e-4d00-08dc57ae855e",
        type: CaptureTreePointCloudType.geoslam,
        uri: "someUrl",
        md5Hash: "5a17e2cf6f2bec068e61cd28ee026baf",
        fileSize: 1299615744,
        fileName: "d0445670-ffed-42a8-9c5e-471ea0407927.geoslam",
      },
      {
        id: "28f0b4d9-6748-4936-1100-08dc57b7c0e1",
        type: CaptureTreePointCloudType.laz,
        uri: "someUrl",
        md5Hash: "30369a1aa5aad77c4869b2658c49bc1a",
        fileSize: 593275292,
        fileName: "2024-01-31_13-24-28.laz",
      },
      {
        id: "54e51064-9627-4d86-6700-08dc57ba1fbf",
        type: CaptureTreePointCloudType.e57,
        uri: "someUrl",
        md5Hash: "7aa9feb013b14231272d68bd232c11e4",
        fileSize: 3447575552,
        fileName: "flash_scan.e57",
      },
    ],
    id: "26bafc4b-7482-4d20-4c00-08dc57ae855e",
    parentId: "609149c3-16f0-4dae-23a6-08dc976e072b",
    name: "2024-01-31_13-24-28",
    pose: {
      pos: {
        x: 0.0,
        y: 0.0,
        z: 0.0,
      },
      scale: {
        x: 1.0,
        y: 1.0,
        z: 1.0,
      },
      rot: {
        x: 0.0,
        y: 0.0,
        z: 0.0,
        w: 1.0,
      },
    },
    type: CaptureTreeEntityType.orbisScan,
    createdAt: "2024-06-28T12:30:08.2801267+00:00",
    createdBy: "98e4cc79-779b-4f82-a490-6bd72d709b5e",
    lastPatchedAt: "2024-06-28T12:30:08.2801267+00:00",
    lastPatchedBy: "98e4cc79-779b-4f82-a490-6bd72d709b5e",
  },
  {
    pointClouds: [
      {
        id: "76c62067-95e8-4e9c-6700-08dc57ae85e5",
        type: CaptureTreePointCloudType.geoslam,
        uri: "someUrl",
        md5Hash: "48173125953c63d752815780ea8d55d2",
        fileSize: 1360039936,
        fileName: "1c14fd5f-df6b-4b37-9c6c-e57f2d0cdaf0.geoslam",
      },
      {
        id: "cf3becac-8d49-4c5e-da80-08dc57baa878",
        type: CaptureTreePointCloudType.laz,
        uri: "someUrl",
        md5Hash: "cfbd9f147b3b95f30bed74cca369e006",
        fileSize: 805797993,
        fileName: "2024-01-31_12-59-29.laz",
      },
      {
        id: "7232e067-47c4-4408-7500-08dc57bea90b",
        type: CaptureTreePointCloudType.e57,
        uri: "someUrl",
        md5Hash: "aaea5b4d6831df53fc4b67d126301284",
        fileSize: 5387516928,
        fileName: "flash_scan.e57",
      },
    ],
    id: "e1e87e98-ee92-4da7-6600-08dc57ae85e5",
    parentId: "609149c3-16f0-4dae-23a6-08dc976e072b",
    name: "2024-01-31_12-59-29",
    pose: {
      pos: {
        x: 0.0,
        y: 0.0,
        z: 0.0,
      },
      scale: {
        x: 1.0,
        y: 1.0,
        z: 1.0,
      },
      rot: {
        x: 0.0,
        y: 0.0,
        z: 0.0,
        w: 1.0,
      },
    },
    type: CaptureTreeEntityType.orbisScan,
    createdAt: "2024-06-28T12:30:08.2817837+00:00",
    createdBy: "98e4cc79-779b-4f82-a490-6bd72d709b5e",
    lastPatchedAt: "2024-06-28T12:30:08.2817837+00:00",
    lastPatchedBy: "98e4cc79-779b-4f82-a490-6bd72d709b5e",
  },
  {
    pointClouds: [
      {
        id: "c6342941-11f6-473b-5d00-08dc8ada43c5",
        type: CaptureTreePointCloudType.laz,
        uri: "someUrl",
        md5Hash: "83e3deef834f69bfde65e279f44ec141",
        fileSize: 1951681556,
        fileName: "tmpwc1bngfm.merge-output.laz",
      },
    ],
    id: "dfb345f3-c463-4550-5c00-08dc8ada43c5",
    parentId: "609149c3-16f0-4dae-23a6-08dc976e072b",
    name: "Merged PC",
    pose: {
      pos: {
        x: 0.0,
        y: 0.0,
        z: 0.0,
      },
      scale: {
        x: 1.0,
        y: 1.0,
        z: 1.0,
      },
      rot: {
        x: 0.0,
        y: 0.0,
        z: 0.0,
        w: 1.0,
      },
    },
    type: CaptureTreeEntityType.orbisScan,
    createdAt: "2024-06-28T12:30:08.2833476+00:00",
    createdBy: "98e4cc79-779b-4f82-a490-6bd72d709b5e",
    lastPatchedAt: "2024-06-28T12:30:08.2833476+00:00",
    lastPatchedBy: "98e4cc79-779b-4f82-a490-6bd72d709b5e",
  },
  {
    pointClouds: [
      {
        id: "95027b40-ace5-497c-4000-08dc8fa601e9",
        type: CaptureTreePointCloudType.laz,
        uri: "someUrl",
        md5Hash: "0795ae09b7140365ea01bf25522745bd",
        fileSize: 1145863961,
        fileName: "tmpvzjcnoyp.merge-output.laz",
      },
    ],
    id: "6519a4bc-1e92-44c4-3e80-08dc8fa601e9",
    parentId: "609149c3-16f0-4dae-23a6-08dc976e072b",
    name: "test_bilal",
    pose: {
      pos: {
        x: 0.0,
        y: 0.0,
        z: 0.0,
      },
      scale: {
        x: 1.0,
        y: 1.0,
        z: 1.0,
      },
      rot: {
        x: 0.0,
        y: 0.0,
        z: 0.0,
        w: 1.0,
      },
    },
    type: CaptureTreeEntityType.orbisScan,
    createdAt: "2024-06-28T12:30:08.2838371+00:00",
    createdBy: "98e4cc79-779b-4f82-a490-6bd72d709b5e",
    lastPatchedAt: "2024-06-28T12:30:08.2838371+00:00",
    lastPatchedBy: "98e4cc79-779b-4f82-a490-6bd72d709b5e",
  },
  {
    pointClouds: [
      {
        externalId: "70be314d-c1fc-4e46-b639-a6097d5b7e86",
        id: "8df92222-53e5-463d-a236-4d81ff49f2d2",
        type: CaptureTreePointCloudType.elsRaw,
        uri: "someUrl",
        md5Hash: "07c731a9f0f1ab8f5c879bdad2cdd072",
        fileSize: 1145863961,
        fileName: "dummy_1.gls",
      },
    ],
    id: "a5a0dfd2-46cd-4a07-990f-560c53f00d7c",
    parentId: "609149c3-16f0-4dae-23a6-08dc976e072b",
    name: "dataset_01",
    pose: {
      pos: {
        x: 0.0,
        y: 0.0,
        z: 0.0,
      },
      scale: {
        x: 1.0,
        y: 1.0,
        z: 1.0,
      },
      rot: {
        x: 0.0,
        y: 0.0,
        z: 0.0,
        w: 1.0,
      },
    },
    type: CaptureTreeEntityType.elsScan,
    createdAt: "2024-06-28T12:30:08.2838371+00:00",
    createdBy: "98e4cc79-779b-4f82-a490-6bd72d709b5e",
    lastPatchedAt: "2024-06-28T12:30:08.2838371+00:00",
    lastPatchedBy: "98e4cc79-779b-4f82-a490-6bd72d709b5e",
  },
  {
    pointClouds: [
      {
        externalId: "8d9fd30b-9909-4e4c-bd80-5a538a72a410",
        id: "9c83c896-c960-43d4-a3c7-5f0a61897a8f",
        type: CaptureTreePointCloudType.elsProcessed,
        uri: "someUrl",
        md5Hash: "07c731a9f0f1ab8f5c879bdad2cdd072",
        fileSize: 1145863961,
        fileName: "dummy_2.gls",
      },
    ],
    id: "4513f3e2-41cc-4d2a-8ab8-89b39dbb9119",
    parentId: "609149c3-16f0-4dae-23a6-08dc976e072b",
    name: "dataset_02",
    pose: {
      pos: {
        x: 0.0,
        y: 0.0,
        z: 0.0,
      },
      scale: {
        x: 1.0,
        y: 1.0,
        z: 1.0,
      },
      rot: {
        x: 0.0,
        y: 0.0,
        z: 0.0,
        w: 1.0,
      },
    },
    type: CaptureTreeEntityType.elsScan,
    createdAt: "2024-06-28T12:30:08.2838371+00:00",
    createdBy: "98e4cc79-779b-4f82-a490-6bd72d709b5e",
    lastPatchedAt: "2024-06-28T12:30:08.2838371+00:00",
    lastPatchedBy: "98e4cc79-779b-4f82-a490-6bd72d709b5e",
  },
  {
    id: "111e5357-1d71-446b-9f98-c07645b7ea20",
    parentId: "609149c3-16f0-4dae-23a6-08dc976e072b",
    name: "Cluster B",
    pose: {
      pos: {
        x: 0.0,
        y: 0.0,
        z: 0.0,
      },
      scale: {
        x: 1.0,
        y: 1.0,
        z: 1.0,
      },
      rot: {
        x: 0.0,
        y: 0.0,
        z: 0.0,
        w: 1.0,
      },
    },
    type: CaptureTreeEntityType.cluster,
    createdAt: "2024-06-28T12:30:08.2611264+00:00",
    createdBy: "98e4cc79-779b-4f82-a490-6bd72d709b5e",
    lastPatchedAt: "2024-06-28T12:30:08.2611264+00:00",
    lastPatchedBy: "98e4cc79-779b-4f82-a490-6bd72d709b5e",
  },
  {
    pointClouds: [
      {
        externalId: "46952f18-aa2e-447e-aae8-03678f2d0a0b",
        id: "cf019492-5788-4bca-af6f-7b1faeb778eb",
        type: CaptureTreePointCloudType.elsProcessed,
        uri: "someUrl",
        md5Hash: "07c731a9f0f1ab8f5c879bdad2cdd072",
        fileSize: 1145863961,
        fileName: "dummy_3.gls",
      },
      {
        id: "567c4c57-60d1-4fc2-0520-08dcacb09f0d",
        type: CaptureTreePointCloudType.e57,
        uri: "someUrl",
        md5Hash: "07c731a9f0f1ab8f5c879bdad2cdd072",
        fileSize: 1145863961,
        fileName: "7ca1bdc1-f0c3-4354-7501-08dcacafc11f.e57",
      },
    ],
    id: "0f2e9e19-abf0-4665-8078-52c7921e7647",
    parentId: "111e5357-1d71-446b-9f98-c07645b7ea20",
    name: "dataset_03",
    pose: {
      pos: {
        x: 0.0,
        y: 0.0,
        z: 0.0,
      },
      scale: {
        x: 1.0,
        y: 1.0,
        z: 1.0,
      },
      rot: {
        x: 0.0,
        y: 0.0,
        z: 0.0,
        w: 1.0,
      },
    },
    type: CaptureTreeEntityType.elsScan,
    createdAt: "2024-06-28T12:30:08.2838371+00:00",
    createdBy: "98e4cc79-779b-4f82-a490-6bd72d709b5e",
    lastPatchedAt: "2024-06-28T12:30:08.2838371+00:00",
    lastPatchedBy: "98e4cc79-779b-4f82-a490-6bd72d709b5e",
  },
];
