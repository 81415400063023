import { Box, useMediaQuery, useTheme } from "@mui/material";
import FaroSphereLogo from "@assets/icons/faro-sphere-xg-logo-color.svg?react";
import { sphereColors } from "@styles/common-colors";
import { FaroIconButton } from "@components/common/faro-icon-button";
import MenuIcon from "@assets/icons/generic-menu_l.svg?react";
// Use Grid version 2, because it will be the stable version in the newer MUI versions
import Grid from "@mui/material/Unstable_Grid2";
import { useAppDispatch, useAppSelector } from "@store/store-helper";
import { updateSidebar } from "@store/ui/ui-slice";
import { FaroBreadcrumbs } from "@components/header/faro-breadcrumbs";
import { sidebarSelector } from "@store/ui/ui-selector";
import { useEffect } from "react";
import { DEFAULT_TITLE_FONT_SIZE } from "@styles/common-styles";
import { MainPageLayoutProps } from "@components/page-layout/main-page-layout";

type Props = Pick<
  MainPageLayoutProps,
  | "shouldShowLogo"
  | "shouldHideOpenButton"
  | "shouldShowSidebar"
  | "topLeftHeaderButton"
>;

/** Content for the left side of layout header */
export function HeaderLayoutLeft({
  shouldShowLogo,
  shouldHideOpenButton,
  topLeftHeaderButton,
  shouldShowSidebar,
}: Props): JSX.Element {
  const dispatch = useAppDispatch();
  const sidebar = useAppSelector(sidebarSelector);
  const theme = useTheme();
  const isScreenSmallAndLarger = useMediaQuery(theme.breakpoints.up("sm"));

  useEffect(() => {
    dispatch(updateSidebar({ ...sidebar, isVisible: shouldShowSidebar }));
    // eslint-disable-next-line react-hooks/exhaustive-deps -- Putting sidebar on dependency will create a loop
  }, [dispatch, shouldShowSidebar]);

  return (
    <Grid container alignItems="center" flexGrow={1} flexBasis={0}>
      {/* Large button to show on the left side of header */}
      {topLeftHeaderButton}

      {shouldShowLogo && (
        <Box component="div">
          <FaroSphereLogo />
        </Box>
      )}

      {/* Button to open drawer */}
      {!isScreenSmallAndLarger && !shouldHideOpenButton && (
        <Grid alignItems="center">
          <Box
            component="div"
            sx={{
              color: sphereColors.gray800,
              fontWeight: 600,
              fontSize: DEFAULT_TITLE_FONT_SIZE,
              lineHeight: "28px",
              letterSpacing: "0.16px",
            }}
          >
            <FaroIconButton
              aria-label="open drawer"
              iconButtonProps={{
                edge: "start",
                sx: {
                  mr: 2,
                  display: { sm: "none" },
                },
              }}
              component={MenuIcon}
              iconSize="20px"
              onClick={() => {
                dispatch(updateSidebar({ isOpenOnMobile: true }));
              }}
            />
          </Box>
        </Grid>
      )}

      {isScreenSmallAndLarger && (
        <Grid alignItems="center">
          <FaroBreadcrumbs />
        </Grid>
      )}
    </Grid>
  );
}
