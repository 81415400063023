import { GroupTypes } from "@custom-types/group-types";
import { BaseMembersProps, MemberTypes } from "@custom-types/member-types";
import { CSVExporter } from "@stellar/web-core";
import {
  getMemberStatus,
  getPrettyName,
  isMemberActive,
} from "@utils/user-utils";
import { formatUserRoleType } from "@utils/data-display";
import { DASH } from "@utils/ui-utils";
import { getRoleHeader } from "@components/table/members/members-table-utils";
import { MemberTableSubject } from "@components/common/faro-table/faro-table-types";
import { BaseProjectsProps, SdbProject } from "@custom-types/project-types";
import {
  getProjectArea,
  getProjectClientName,
  getProjectManager,
  getSphereViewerUrl,
} from "src/utils/project-utils";
import { Markup } from "@custom-types/project-markups-types";
import { APITypes, SphereDashboardAPITypes } from "@stellar/api-logic";
import { getMarkupAssigneeName } from "@pages/project-details/project-markups/markup-assignee-utils";
import { getMarkupStatusLabel } from "@pages/project-details/project-markups/status/markup-status-utils";
import { getMemberNameById, getMembersMapById } from "@utils/member-utils";
import { FormatDate } from "@hooks/use-date-time";
import { capitalizeFirstLetter } from "@utils/string-utils";
import { TEAMS_DISPLAY_NAME } from "@src/constants/team-constants";
import { isTeam } from "@utils/team-utils";
import { SdbTeam } from "@custom-types/teams-types";
import {
  CreditSubscription,
  ConsumptionDisplayReason,
  CreditTransaction,
  TransactionActions,
} from "@custom-types/credit-types";
import { DateTime } from "luxon";
import {
  getSubscriptionPackageName,
  getSubscriptionStatus,
} from "@utils/credit-transaction-utils";

/**
 * Create CSV file out of groups array and create a download handler
 */
export function downloadGroupDataAsCSV(
  groups: GroupTypes[],
  formatDate: FormatDate
): void {
  const csvData = groups.map((group) => ({
    /* eslint-disable @typescript-eslint/naming-convention -- Many of the keys are used for CSV export */
    ID: group.id,
    Name: group.name,
    "Created At": formatDate(group.createdAt),
    Managers: group.numManagers,
    Projects: group.numProjects,
    "Sqft Assigned":
      group.sqftAssigned !== -1 ? group.sqftAssigned : "unrestricted",
    "Sqft Used": group.sqftUsed,
    /* eslint-enable */
  }));

  CSVExporter.toFileDownload(csvData, "Groups List.csv");
}

/**
 * Create CSV file out of teams array and create a download handler
 */
export function downloadTeamDataAsCSV(
  teams: SdbTeam[],
  formatDate: FormatDate
): void {
  const csvData = teams.map((team) => ({
    /* eslint-disable @typescript-eslint/naming-convention -- Many of the keys are used for CSV export */
    ID: team.id,
    Name: team.name,
    Description: team.description,
    "Number of Members": team.memberCount,
    "Created At": formatDate(team.createdAt),
    Role: formatUserRoleType(team.role),
    /* eslint-enable */
  }));

  CSVExporter.toFileDownload(
    csvData,
    `${capitalizeFirstLetter(TEAMS_DISPLAY_NAME)} List.csv`
  );
}

interface DownloadMembersDataAsCSVProps extends BaseMembersProps {
  /** The name of the file to be downloaded */
  fileName: string;

  /** The subject type of the member */
  subjectType: MemberTableSubject;
}

/**
 * Create CSV file out of project members array and create a download handler
 */
export function downloadMembersDataAsCSV({
  fileName,
  members,
  subjectType,
}: DownloadMembersDataAsCSVProps): void {
  const csvData = members.map((member) => {
    const userData = {
      /* eslint-disable @typescript-eslint/naming-convention -- Many of the keys are used for CSV export */
      ID: isTeam(member) ? member.email : member.identity,
      Name: isMemberActive(member) ? getPrettyName(member) : DASH,
      Email: isTeam(member) ? DASH : member.email,
      status: isTeam(member) ? DASH : getMemberStatus({ member }),
      /* eslint-enable */
    };

    // Skipping Role column when subject type is team
    if (subjectType !== "team") {
      Object.assign(userData, {
        [getRoleHeader(subjectType)]: formatUserRoleType(member.role),
      });
    }

    return userData;
  });

  CSVExporter.toFileDownload(csvData, fileName);
}

interface DownloadProjectsDataAsCSVProps extends BaseProjectsProps {
  /** The name of the file to be downloaded */
  fileName: string;

  /** The function to format date. Needs to be passed manually when not used in a hook */
  formatDate: FormatDate;
}

export function downloadProjectsDataAsCSV({
  fileName,
  projects,
  formatDate,
}: DownloadProjectsDataAsCSVProps): void {
  const csvData = projects.map((project) => ({
    /* eslint-disable @typescript-eslint/naming-convention -- Many of the keys are used for CSV export */
    "Project Name": project.name,
    Client: getProjectClientName(project),
    Area: getProjectArea(project),
    "Project Manager Name": projectManagerName(project),
    "Project Manager Email": getProjectManager(project)?.email || DASH,
    Edited: formatDate(project.modifiedAt),
    "Created At": formatDate(project.createdAt),
    "Project Link": getSphereViewerUrl({ projectId: project.id }).href,
    /* eslint-enable */
  }));

  CSVExporter.toFileDownload(csvData, fileName);
}

/**
 * Returns the project manager name
 * If the project manager is active, it will return the name otherwise it will return DASH
 */
function projectManagerName(project: SdbProject): string {
  const projectManager = getProjectManager(project);

  if (projectManager && isMemberActive(projectManager)) {
    return getPrettyName(projectManager);
  }
  return DASH;
}

interface DownloadMarkupsDataAsCSVProps {
  /** List of selected markups in table */
  markups: Markup[];

  /** List of company members */
  companyMembers: MemberTypes[];

  /** List of project members */
  projectMembers: SphereDashboardAPITypes.IProjectMemberBase[];

  /** The name of the file to be downloaded */
  fileName: string;

  /** Callback to return the URL of the markup either in WebEditor or Viewer */
  markupHref: (markup: Markup) => string | undefined;

  /** The function to format date. Needs to be passed manually when not used in a hook */
  formatDate: FormatDate;
}

/** Create CSV file out of markups array and create a download handler */
export function downloadMarkupDataAsCSV({
  markups,
  companyMembers,
  projectMembers,
  fileName,
  markupHref,
  formatDate,
}: DownloadMarkupsDataAsCSVProps): void {
  const csvData = markups.map((markup) => ({
    /* eslint-disable @typescript-eslint/naming-convention -- Many of the keys are used for CSV export */
    ID: markup.id,
    Name: markup.name,
    Assignee:
      getMarkupAssigneeName({
        assignee: markup.assignee,
        companyMembers,
        projectMembers,
      }) ?? DASH,
    "Created At": formatDate(markup.createdAt),
    "Created By":
      getMemberNameById({
        memberId: markup.createdBy,
        companyMembers,
        projectMembers,
      }) ?? DASH,
    "Due Date": markup.dueDate?.value ? formatDate(markup.dueDate.value) : DASH,
    Status: getMarkupStatusLabel(markup.status?.value),
    Tags: markup.labels?.map((label) => label.name).join(", ") ?? DASH,
    "Number of Attachments": markup.attachmentsCount,
    Description: markup.descr ?? DASH,
    Url: markupHref(markup) ?? DASH,
    /* eslint-enable */
  }));

  CSVExporter.toFileDownload(csvData, fileName);
}

interface DownloadTransactionsDataAsCSVProps extends BaseProjectsProps {
  /** List of credit transactions */
  transactions: CreditTransaction[];

  /** List of company members */
  companyMembers: MemberTypes[];

  /** The name of the file to be downloaded */
  fileName: string;

  /** The function to format date. Needs to be passed manually when not used in a hook */
  formatDate: FormatDate;
}

interface DownloadPurchasesDataAsCSVProps {
  /** List of credit subscriptions */
  subscriptions: CreditSubscription[];

  /** The name of the file to be downloaded */
  fileName: string;

  /** The function to format date. Needs to be passed manually if not used in a hook */
  formatDate: FormatDate;
}

/** Create CSV file out of transactions array and create a download handler */
export function downloadTransactionsDataAsCSV({
  transactions,
  companyMembers,
  projects,
  fileName,
  formatDate,
}: DownloadTransactionsDataAsCSVProps): void {
  // Cache the project names using the project ID as the key
  const projectMap = new Map<APITypes.ProjectId | undefined, string>(
    projects.map((project) => [project.id, project.name])
  );
  const membersMap = getMembersMapById(companyMembers);

  const csvData = transactions.map((transaction) => {
    const member = transaction.context.userId
      ? membersMap.get(transaction.context.userId)
      : undefined;
    return {
      /* eslint-disable @typescript-eslint/naming-convention -- Many of the keys are used for CSV export */
      Date: formatDate(transaction.timestamp, DateTime.DATE_MED),
      "User Name": member?.name ?? DASH,
      "User Email": member?.email ?? DASH,
      Description:
        ConsumptionDisplayReason[
          transaction.action.data.consumptionReason ?? ""
        ],
      "Project ID": transaction.context.projectId ?? DASH,
      "Project Name": transaction.context.projectId
        ? projectMap.get(transaction.context.projectId)
        : DASH,
      Credits:
        transaction.action.category === TransactionActions.creditConsumption
          ? -Math.abs(transaction.action.data.creditAmount)
          : Math.abs(transaction.action.data.creditAmount),
      /* eslint-enable */
    };
  });

  CSVExporter.toFileDownload(csvData, fileName);
}

/** Create CSV file out of credit subscriptions array and create a download handler */
export function downloadPurchasesDataAsCSV({
  subscriptions,
  fileName,
  formatDate,
}: DownloadPurchasesDataAsCSVProps): void {
  const csvData = subscriptions.map((subscription) => ({
    /* eslint-disable @typescript-eslint/naming-convention -- Many of the keys are used for CSV export */
    Package: getSubscriptionPackageName(subscription),
    "Purchased Date": formatDate(subscription.startDate),
    "Expiration Date": formatDate(subscription.expiresAt),
    Status: getSubscriptionStatus(subscription),
    "Credits Remaining": subscription.availableCredit,
    /* eslint-enable */
  }));

  CSVExporter.toFileDownload(csvData, fileName);
}
