import { Stack, Typography } from "@mui/material";
import { CreditsDialog } from "@pages/settings/subscription-settings/credits/credits-dialog";
import { CreditViewMode } from "@custom-types/credit-types";

interface Props {
  /** Show the credit balance */
  creditBalance: string;
}

/**
 * Displays the user's credit balance along with an action button to view transactions.
 *
 * This component shows the current credit balance and provides a button to navigate
 * to the credit transactions page. When the button is clicked, an event is tracked
 * for analytics purposes.
 */
export function CreditsBalanceItem({ creditBalance }: Props): JSX.Element {
  return (
    <Stack
      flexDirection="row"
      justifyContent="space-between"
      alignItems="center"
      width="100%"
    >
      <Typography data-testid="credits-balance-item-balance">
        <var>{creditBalance}</var> credits
      </Typography>

      <CreditsDialog buttonLabel="View transactions" initialViewMode={CreditViewMode.transaction} />
    </Stack>
  );
}
