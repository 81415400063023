import { useEffect } from "react";
import * as Sentry from "@sentry/react";
import { runtimeConfig } from "@src/runtime-config";
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom";
import { isProductionEnvironment } from "@utils/env-utils";

/* eslint-disable @typescript-eslint/no-magic-numbers -- Setting up Sentry configuration */
Sentry.init({
  dsn: runtimeConfig.features.sentryDSNKey,
  environment: runtimeConfig.appEnv,
  release: runtimeConfig.appVersion,

  // Set up tracing to track down transactions to understand the performance of the application
  // Sets the tracing sample rate to be 10% in production and 0 in testing.
  // This is because we have limited transactions in Sentry (100k).
  // https://docs.sentry.io/platforms/javascript/guides/react/tracing/
  tracesSampleRate: isProductionEnvironment ? 0.1 : 0,

  // Sets the sample rate to be 10% as suggested by Sentry in production and 0% in testing to not reach our Sentry limits
  replaysSessionSampleRate: isProductionEnvironment ? 0.1 : 0,

  // Sample all sessions that an error occurs
  replaysOnErrorSampleRate: 1,
  integrations: [
    // See docs for support of different versions of variation of react router
    // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),

    // This integration is used to mask sensitive data in the replay session
    Sentry.replayIntegration({
      /* eslint-disable @typescript-eslint/naming-convention -- properties coming from external package */
      maskAllText: true,
      blockAllMedia: true,
      maskAllInputs: true,
      /* eslint-enable */
    }),
  ],
});
/* eslint-enable */
