import { FaroDialog } from "@components/common/dialog/faro-dialog";
import { Box, Typography } from "@mui/material";
import { sphereColors } from "@styles/common-colors";
import { useCallback, useState } from "react";
import { CardSelector, CardItemDetails } from "@faro-lotv/flat-ui";
import { CREDIT_PACKAGES, CreditPackageValue } from "@custom-types/credit-types";
import { formatPrice } from "@utils/data-display";
import { useAppSelector } from "@store/store-helper";
import { useErrorContext } from "@context-providers/error-boundary/error-handling-context";
import { useCoreApiClient } from "@api/use-core-api-client";
import { selectedSdbCompanySelector } from "@store/sdb-company/sdb-company-selector";
import { CoreAPITypes } from "@stellar/api-logic";
import { useTrackEvent } from "@utils/track-event/use-track-event";
import { WorkspaceEvents } from "@utils/track-event/track-event-list";
import { SphereDivider } from "@components/common/sphere-divider";
import { DEFAULT_INPUT_FONT_SIZE, DEFAULT_TEXT_FONT_SIZE } from "@styles/common-styles";
import { CreditFeatureItem } from "@pages/settings/subscription-settings/credits/credit-feature-item";

/** List of credit-related features */ 
const CREDIT_FEATURES = [
  "Generate floorplans seamlessly using credits.",
  "Purchase credits in flexible blocks tailored to your needs.",
  "Use credits within 12 months before they expire.",
];

interface AddCreditDialogProps {
  /** Callback to close the dialog */
  onCloseDialog: () => void;
  
  /** Callback to request a quote */
  onQuoteRequested: () => void;
}

type CardItem = CardItemDetails<CreditPackageValue>;

/**
 * List of available credit packages for purchase.
 * Maps credit package details to the `CardItemDetails` type,
 * making them compatible with the `CardSelector` component.
 */
const creditPackages: CardItem[] = Object.values(CREDIT_PACKAGES).map((pkg) => ({
  title: `${pkg.value} credits`,
  subtitle: `$${formatPrice(pkg.price, "en-US")}`,
  badgeText: pkg.name,
  value: pkg.value,
}));

/**
 * Render a dialog for requesting a credit quote.  
 * Displays available credit packages, handles selection,  
 * and sends the request to the backend with error handling.
 */
export function AddCreditQuoteDialog({
  onCloseDialog,
  onQuoteRequested,
}: AddCreditDialogProps): JSX.Element {
  const [isLoading, setIsLoading] = useState(false);
  const [selectedPackage, setSelectedPackage] = useState<CardItemDetails<CreditPackageValue> | undefined>(undefined);
  const selectedCompany = useAppSelector(selectedSdbCompanySelector);
  const coreApiClient = useCoreApiClient();
  const { handleErrorWithToast } = useErrorContext();
  const { trackEvent } = useTrackEvent();
  
  /**
   * Handles the request to get a quote for the selected credit package.
   */
   const handleRequestCreditQuote = useCallback(async () => {
    if (!selectedPackage) {
      return;
    }

    setIsLoading(true);
    
    trackEvent({
      name: WorkspaceEvents.requestQuote,
      props: {
        creditsAmount: selectedPackage.value,
      },
    });

    try {
      // Create a message for the notification that contains the selected package details
      const customMessage = `Requesting quote for:
        • Package: ${selectedPackage.title}
        • Price: ${selectedPackage.subtitle}`;

      await coreApiClient.V3.notificationRequest({
        featureName: "Sphere-Dashboard",
        notificationSubject: `Requesting to add ${selectedPackage.value} credits`,
        featureComment: customMessage,
        notificationType: CoreAPITypes.ENotificationType.serviceRequest,
        companyId: selectedCompany?.id,
      });

      onQuoteRequested();
    } catch (error) {
      handleErrorWithToast({
        id: `sendQuoteRequest-${Date.now().toString()}`,
        title: "Request failed. Please try again.",
        error,
      });
    } finally {
      setIsLoading(false);
    }
  }, [
    selectedPackage,
    trackEvent,
    coreApiClient,
    selectedCompany?.id,
    onQuoteRequested,
    handleErrorWithToast,
  ]);

  function handleLearnMore(): void {
    alert(
      "TODO: Add link to get more information about credits https://faro01.atlassian.net/browse/ST-2915"
    );
  }

  return (
    <FaroDialog
      open={true}
      onClose={onCloseDialog}
      title={"Add credits"}
      confirmText="Get quote"
      closeText="Cancel"
      onConfirm={() => {
        void handleRequestCreditQuote();
      }}
      isConfirmLoading={isLoading}
      isConfirmDisabled={isLoading || !selectedPackage}
      maxWidth="md"
      fullWidth={true}
      dialogActionSx={{
        display: "flex",
        flexDirection: { xs: "column", sm: "row" },
        alignItems: "stretch",
      }}
      bottomLeftSideComponent={
        <Box
          sx={{
            fontFamily: "Open Sans",
            display: "inline",
            color: sphereColors.gray800,
          }}
        >
          <Typography
            sx={{
              fontSize: DEFAULT_TEXT_FONT_SIZE,
            }}
          >
            Learn more about{" "}
            <Typography
              component="span"
              onClick={handleLearnMore}
              sx={{
                fontSize: "inherit",
                fontWeight: 600,
                textDecoration: "underline",
                cursor: "pointer",
              }}
            >
              credits
            </Typography>
          </Typography>
        </Box>
      }
    >
      <Box sx={{ width: "100%" }}>
        <CardSelector
          cardItems={creditPackages}
          selectedValue={selectedPackage?.value}
          onSelect={
            (value: CardItem["value"], cardItem: CardItem) => setSelectedPackage(cardItem)
          }
        />
      </Box>

      <SphereDivider
        sx={{ height: "1px", color: sphereColors.gray200, my: 3 }}
      />

      <Box
        sx={{
          pb: 3,
        }}
      >
        <Typography
          sx={{
            fontSize: DEFAULT_INPUT_FONT_SIZE,
            lineHeight: "20px",
          }}
        >
          Sphere XG credits are a digital currency for specific cloud-based
          services, enabling your team to generate floorplans within your
          projects.
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          paddingY: "16px",
        }}
      >
        {CREDIT_FEATURES.map((text, index) => (
          <CreditFeatureItem key={index} featureText={text} />
        ))}
      </Box>
    </FaroDialog>
  );
}
