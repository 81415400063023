import { CaptureTreeEntityRevision, RevisionStatus } from "@faro-lotv/service-wires";
import { ReactNode } from "react";
import { isClusterEntity, isScanEntity } from "@utils/capture-tree/capture-tree-utils";
import { NounForPluralize, nounPluralize } from "@utils/data-display";
import { CaptureTreeRevision } from "@custom-types/capture-tree/capture-tree-types";
import { isEqual } from "lodash";

/** Result of summarizeRevisionChanges(). */
export interface RevisionChangeSummary {
  // Number of changes for each type.
  scansAdded: number;
  scansModified: number;
  scansDeleted: number;
  clustersAdded: number;
  clustersModified: number;
  clustersDeleted: number;
  otherChanges: number;

  /** Total number of changes (sum of the above). */
  sum: number;
  /** Array of human-readable text describing the changes, including only non-zero values. */
  textJsx: ReactNode[];
}

/**
 * @returns True if the two provided revisions are different (comparing their ID),
 *          or if the summary of their entity changes is different.
 */
export function wasDraftUpdated(
  openDraftRevision1: CaptureTreeRevision,
  openDraftChanges1: CaptureTreeEntityRevision[] | RevisionChangeSummary,
  openDraftRevision2: CaptureTreeRevision,
  openDraftChanges2: CaptureTreeEntityRevision[] | RevisionChangeSummary
): boolean {
  const summary1 = Array.isArray(openDraftChanges1) ? summarizeRevisionChanges(openDraftChanges1) : openDraftChanges1;
  const summary2 = Array.isArray(openDraftChanges2) ? summarizeRevisionChanges(openDraftChanges2) : openDraftChanges2;

  return (
    openDraftRevision1.id !== openDraftRevision2.id ||
    !isEqual(summary1, summary2)
  );
}

// Helper for summarizeRevisionChanges().
function addChange(textJsx: ReactNode[], counter: number, verb: string, word: NounForPluralize): void {
  if (counter > 0) {
    const wordPl = nounPluralize({ counter, word, shouldShowCounter: false });
    const wasWere = counter === 1 ? "was" : "were";
    textJsx.push(<><var>{counter}</var> {`${wordPl} ${wasWere} ${verb}`}</>);
  }
}

// Helper for summarizeRevisionChanges().
function addOtherChanges(textJsx: ReactNode[], counter: number): void {
  // Show the "other changes" only as last resort, if there is nothing else to display.
  if (counter > 0 && textJsx.length === 0) {
    const wordPl = nounPluralize({ counter, word: "change", shouldShowCounter: false });
    const wasWere = counter === 1 ? "was" : "were";
    const part1 = "No scans or clusters were modified";
    textJsx.push(<>{`${part1}, but there ${wasWere}`} <var>{counter}</var> {`other ${wordPl}.`}</>);
  }
}

/** @returns Human-readable summary of the changes in the given entities of a revision. */
export function summarizeRevisionChanges(entities: CaptureTreeEntityRevision[]): RevisionChangeSummary {
  let scansAdded = 0, scansModified = 0, scansDeleted = 0;
  let clustersAdded = 0, clustersModified = 0, clustersDeleted = 0;
  let otherChanges = 0;
  for (const entity of entities) {
    const isScan = isScanEntity(entity);
    const isCluster = isClusterEntity(entity);
    if (isScan && entity.status === RevisionStatus.added) {
      scansAdded++;
    } else if (isScan && entity.status === RevisionStatus.modified) {
      scansModified++;
    } else if (isScan && (entity.status === RevisionStatus.deleted || entity.status === RevisionStatus.deletedExplicitly)) {
      scansDeleted++;
    } else if (isCluster && entity.status === RevisionStatus.added) {
      clustersAdded++;
    } else if (isCluster && entity.status === RevisionStatus.modified) {
      clustersModified++;
    } else if (isCluster && (entity.status === RevisionStatus.deleted || entity.status === RevisionStatus.deletedExplicitly)) {
      clustersDeleted++;
    } else if (entity.status !== RevisionStatus.initialized) {
      // RevisionStatus.addedAndDeleted is currently counted as "other change".
      otherChanges++;
    }
  }
  const sum = scansAdded + scansModified + scansDeleted + clustersAdded + clustersModified + clustersDeleted + otherChanges;

  const textJsx: ReactNode[] = [];
  addChange(textJsx, scansAdded, "added", "scan");
  addChange(textJsx, scansModified, "modified", "scan");
  addChange(textJsx, scansDeleted, "deleted", "scan");
  addChange(textJsx, clustersAdded, "added", "cluster");
  addChange(textJsx, clustersModified, "modified", "cluster");
  addChange(textJsx, clustersDeleted, "deleted", "cluster");
  addOtherChanges(textJsx, otherChanges);

  return {
    scansAdded, scansModified, scansDeleted, clustersAdded, clustersModified, clustersDeleted, otherChanges, sum, textJsx,
  };
}

/** @return The `summary` adapted for Amplitude tracking. */
export function changeSummaryForTracking(summary: RevisionChangeSummary): Omit<RevisionChangeSummary, "textJsx"> {
  // "textJsx" provides no additional information, and is not allowed in our Amplitude event type.
  const { textJsx, ...rest } = summary;
  return rest;
}
