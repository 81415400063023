import {
  CREDIT_PACKAGES,
  CreditSubscription,
  CreditSubscriptionStatus,
  CreditTransaction,
  TransactionActions,
} from "@custom-types/credit-types";
import { nounPluralize } from "@utils/data-display";

/** Calculates and returns the total credit used from transaction list */
export function calculateCreditUsed(transactions: CreditTransaction[]): number {
  return transactions
    .filter(
      (transaction) =>
        transaction.action.category === TransactionActions.creditConsumption
    )
    .reduce(
      (total, transaction) => total + transaction.action.data.creditAmount,
      0
    );
}

/** Calculates the subscription status based on the available credit and expiration date */
export function getSubscriptionStatus(
  subscription: CreditSubscription
): CreditSubscriptionStatus {
  const now = new Date();
  const expirationDate = new Date(subscription.expiresAt);

  // If the expiration date is in the past, the subscription is expired
  if (expirationDate < now) {
    return CreditSubscriptionStatus.expired;
  }

  // If the available credit is equal to the maximum credit, the subscription is active but not yet in use
  if (subscription.availableCredit === subscription.max) {
    return CreditSubscriptionStatus.active;
  }

  // If the available credit is greater than 0 and less than the maximum credit, the subscription is active and in use
  if (
    subscription.availableCredit > 0 &&
    subscription.availableCredit < subscription.max
  ) {
    return CreditSubscriptionStatus.activeInUse;
  }

  // If none of the options above are met, we assume the subscription is used
  return CreditSubscriptionStatus.used;
}

/**
 * Returns the name of the package to show based on the credit amount
 * @param packageName The name of the package
 * @param creditAmount The amount of credits in the package
 */
export function getPackageDisplayName({
  packageName,
  creditAmount,
}: {
  packageName: string;
  creditAmount: number;
}): string {
  const creditsPluralized = nounPluralize({
    shouldShowCounter: true,
    counter: creditAmount,
    word: "credit",
  });
  return `${packageName} ∙ ${creditsPluralized}`;
}

/** Returns the name of the package based on the maximum credit of the subscription */
export function getSubscriptionPackageName(
  subscription: CreditSubscription
): string {
  const matchedPackage = Object.values(CREDIT_PACKAGES).find(
    (pkg) => subscription.max === pkg.value
  );

  return matchedPackage
    ? getPackageDisplayName({
      packageName: matchedPackage.name,
      creditAmount: matchedPackage.value,
    })
    : getPackageDisplayName({
      packageName: "Custom",
      creditAmount: subscription.max,
    });
}
