import { getErrorDisplayMarkup } from "@context-providers/error-boundary/error-boundary-utils";
import { CaptureTreeRevision } from "@custom-types/capture-tree/capture-tree-types";
import { ProjectApi } from "@api/project-api/project-api";
import { FetchRevisionsAndDraftEntitiesAndMainEntitiesResult } from "@hooks/data-management/use-data-management";
import {
  CaptureTreeEntity,
  CaptureTreeEntityRevision,
} from "@faro-lotv/service-wires";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { BaseProjectApiClientProps } from "@store/store-types";
import { isOpenDraftRevision } from "@utils/capture-tree/capture-tree-utils";

interface FetchCaptureTreeForOpenDraftRevisionProps extends BaseProjectApiClientProps {
  /** The ID of the open draft revision */
  openDraftRevisionId: string | null;
}

async function fetchCaptureTreeRaw(projectApiClient: ProjectApi): Promise<CaptureTreeEntity[]> {
  try {
    return await projectApiClient.getCaptureTree();
  } catch (error) {
    throw new Error(getErrorDisplayMarkup(error));
  }
}

async function fetchAllCaptureTreeRevisionsRaw(projectApiClient: ProjectApi): Promise<CaptureTreeRevision[]> {
  try {
    // Revisions in the ProjectAPI are actually called "registration revisions",
    // but we alias them "capture tree revisions" in the Dashboard to avoid confusion.
    return await projectApiClient.getAllRegistrationRevisions();
  } catch (error) {
    throw new Error(getErrorDisplayMarkup(error));
  }
}

async function fetchCaptureTreeForOpenDraftRevisionRaw(
  projectApiClient: ProjectApi,
  openDraftRevisionId: string | null
): Promise<CaptureTreeEntityRevision[]> {
  try {
    return openDraftRevisionId
      ? await projectApiClient.getCaptureTreeForRegistrationRevision(openDraftRevisionId)
      : [];
  } catch (error) {
    throw new Error(getErrorDisplayMarkup(error));
  }
}

export async function fetchCaptureTreeRevisionsAndDraftEntitiesAndMainEntitiesRaw(
  projectApiClient: ProjectApi
): Promise<FetchRevisionsAndDraftEntitiesAndMainEntitiesResult> {
  try {
    const mainEntitiesPromise = fetchCaptureTreeRaw(projectApiClient);
    const revisionsPromise = fetchAllCaptureTreeRevisionsRaw(projectApiClient);
    const [mainEntities, revisions] = await Promise.all([mainEntitiesPromise, revisionsPromise]);
    const openDraftRevision = revisions.find(isOpenDraftRevision);
    const openDraftEntities = await fetchCaptureTreeForOpenDraftRevisionRaw(
      projectApiClient,
      openDraftRevision?.id ?? null
    );

    return { mainEntities, revisions, openDraftRevision, openDraftEntities };
  } catch (error) {
    throw new Error(getErrorDisplayMarkup(error));
  }
}

/** Fetches the list of capture tree entities for the current main/draft revision of the selected project */
export const fetchCaptureTree = createAsyncThunk<
  CaptureTreeEntity[],
  BaseProjectApiClientProps
>(
  "captureTree/fetchCaptureTree",
  async ({ projectApiClient }) => {
    return await fetchCaptureTreeRaw(projectApiClient);
  }
);

/** Fetches the list of capture tree entities for the current draft revision of the selected project */
export const fetchCaptureTreeForOpenDraftRevision = createAsyncThunk<
  CaptureTreeEntityRevision[],
  FetchCaptureTreeForOpenDraftRevisionProps
>(
  "captureTree/fetchCaptureTreeForOpenDraftRevision",
  async ({ projectApiClient, openDraftRevisionId }) => {
    return await fetchCaptureTreeForOpenDraftRevisionRaw(projectApiClient, openDraftRevisionId);
  }
);

/** Fetches the list of all revisions of the selected project */
export const fetchAllCaptureTreeRevisions = createAsyncThunk<
  CaptureTreeRevision[],
  BaseProjectApiClientProps
>("captureTree/fetchAllCaptureTreeRevisions",
  async ({ projectApiClient }) => {
    return await fetchAllCaptureTreeRevisionsRaw(projectApiClient);
  }
);

export const fetchCaptureTreeRevisionsAndDraftEntitiesAndMainEntities = createAsyncThunk<
FetchRevisionsAndDraftEntitiesAndMainEntitiesResult,
  { projectApiClient: ProjectApi }
>(
  "captureTree/fetchCaptureTreeRevisionsAndDraftEntitiesAndMainEntities",
  async ({ projectApiClient }) => {
    return await fetchCaptureTreeRevisionsAndDraftEntitiesAndMainEntitiesRaw(projectApiClient);
  }
);