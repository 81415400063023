import { FaroButton } from "@faro-lotv/flat-ui";
import { WorkspaceEvents } from "@utils/track-event/track-event-list";
import { useTrackEvent } from "@utils/track-event/use-track-event";
import { useState } from "react";
import { AddCreditQuoteDialog } from "@pages/settings/subscription-settings/credits/add-credit-quote-dialog";
import { QuoteConfirmationDialog } from "@pages/settings/subscription-settings/credits/quote-confirmation-dialog";

/** Component to show the button add credits */
export function AddCredits(): JSX.Element {
  const { trackEvent } = useTrackEvent();
  const [isOpenDialog, setIsOpenDialog] = useState(false);
  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] =
    useState(false);

  function handleAddCredits(): void {
    trackEvent({
      name: WorkspaceEvents.openAddCreditsDialog,
    });

    setIsOpenDialog(true);
  }

  return (
    <>
      <FaroButton onClick={handleAddCredits}>Add credits</FaroButton>
      {isOpenDialog && (
        <AddCreditQuoteDialog
          onCloseDialog={() => setIsOpenDialog(false)}
          onQuoteRequested={() => {
            setIsOpenDialog(false);
            setIsConfirmationDialogOpen(true);
          }}
        />
      )}
      {isConfirmationDialogOpen && (
        <QuoteConfirmationDialog
          onClose={() => setIsConfirmationDialogOpen(false)}
        />
      )}
    </>
  );
}
