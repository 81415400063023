import { RegisteredData } from "@pages/project-details/project-data-management/registered-data/registered-data-types";
import { CaptureApiClient, RegistrationState } from "@faro-lotv/service-wires";
import { isSdbBackgroundTaskOngoing } from "@utils/sdb-background-tasks-utils";
import { SdbStatusResultCloudRegistration } from "@custom-types/sdb-background-tasks-types";

/**
 * @returns true if the RegisteredData revision is getting registered, or has been registered,
 *          or failed to register. This excludes canceled revisions.
 */
export function isRegisteringOrRegistered(registeredData: RegisteredData): boolean {
  return isRegistering(registeredData) || isRegistered(registeredData);
}

/**
 * @returns true if the RegisteredData revision is getting registered.
 */
export function isRegistering(registeredData: RegisteredData): boolean {
  // False if the task property is not defined or if is not an ongoing task.
  return !!registeredData.task && isSdbBackgroundTaskOngoing(registeredData.task) &&
    (registeredData.state === RegistrationState.cloudRegistrationStarted || registeredData.state === RegistrationState.started);
}

/**
 * @returns true if the RegisteredData revision has been registered, or failed to register.
 */
export function isRegistered(registeredData: RegisteredData): boolean {
  return registeredData.state === RegistrationState.registered  ||
    (registeredData.state === RegistrationState.merged &&
     registeredData.createdByClient === CaptureApiClient.registrationBackend);
}

/**
 * @returns true if the RegisteredData revision has a task that failed to finish.
 */
export function hasRegisterError(registeredData: RegisteredData): boolean {
  return registeredData.state === RegistrationState.canceled ||
    registeredData.task?.state === "Failed" || registeredData.task?.state === "Aborted";
}

/**
 * @returns true if the RegisteredData revision has a successful task but with bad registration.
 */
export function hasBadRegistration(registeredData: RegisteredData): boolean {
  const statusResult = registeredData.task?.status?.result as SdbStatusResultCloudRegistration | undefined;
  return (registeredData.state === RegistrationState.registered || registeredData.task?.state === "Succeeded") &&
    statusResult?.additionalData?.state === "FAILED";
}
