import {
  GridColDef,
  GridRenderCellParams,
  gridStringOrNumberComparator,
} from "@mui/x-data-grid-pro";
import { FormatDate } from "@hooks/use-date-time";
import { DateTime } from "luxon";
import { faroTableComparator } from "@components/common/faro-table/faro-table-utils";
import { TransactionsHeaders } from "@components/table/transactions/transactions-table-utils";
import {
  ConsumptionDisplayReason,
  CreditTransaction,
  TransactionActions,
} from "@custom-types/credit-types";
import { TransactionUser } from "@components/table/transactions/transaction-user";
import { BaseMembersMapProps } from "@custom-types/member-types";
import { TransactionProject } from "@components/table/transactions/transaction-project";
import { FaroChip } from "@faro-lotv/flat-ui";
import { sphereColors } from "@styles/common-colors";
import { Box } from "@mui/material";
import { BaseProjectsMapProps } from "@custom-types/project-types";
import { SphereTooltip } from "@components/common/sphere-tooltip";
import { withEllipsis } from "@styles/common-styles";
import { resolveUserIdentity } from "@utils/user-utils";
import { nounPluralize } from "@utils/data-display";

interface Props extends BaseProjectsMapProps, BaseMembersMapProps {
  /** The function to format date. Needs to be passed since getMembersColumns is not a hook */
  formatDate: FormatDate;
}

/** Return all the possible columns for Transactions table */
export function getTransactionsColumns({
  formatDate,
  membersMap,
  projectsMap,
}: Props): Record<TransactionsHeaders, GridColDef> {
  return {
    [TransactionsHeaders.date]: {
      field: TransactionsHeaders.date,
      flex: 0.5,
      minWidth: 180,
      renderCell: (
        params: GridRenderCellParams<{ entity: CreditTransaction }>
      ) => {
        return (
          <var>
            {formatDate(params.row.entity.timestamp, DateTime.DATE_MED)}
          </var>
        );
      },
      valueGetter: (_, row: { entity: CreditTransaction }) =>
        Date.parse(row.entity.timestamp),
      // eslint-disable-next-line @typescript-eslint/naming-convention -- name given by package
      sortable: true,
      sortComparator: (v1, v2, cp1, cp2) =>
        faroTableComparator<string>(
          v1,
          v2,
          cp1,
          cp2,
          gridStringOrNumberComparator
        ),
    },

    [TransactionsHeaders.user]: {
      field: TransactionsHeaders.user,
      flex: 1,
      minWidth: 220,
      renderCell: (
        params: GridRenderCellParams<{ entity: CreditTransaction }>
      ) => {
        const userIdentity = resolveUserIdentity(
          params.row.entity.context.userId,
          params.row.entity.context.userEmail
        );

        const member = userIdentity ? membersMap.get(userIdentity) : undefined;

        return <TransactionUser member={member} />;
      },
      valueGetter: (_, row: { entity: CreditTransaction }) => {
        const userIdentity = resolveUserIdentity(
          row.entity.context.userId,
          row.entity.context.userEmail
        );

        const member = userIdentity ? membersMap.get(userIdentity) : undefined;

        return member?.name ?? userIdentity;
      },
      // eslint-disable-next-line @typescript-eslint/naming-convention -- name given by package
      sortable: true,
      sortComparator: (v1, v2, cp1, cp2) =>
        faroTableComparator<string>(
          v1,
          v2,
          cp1,
          cp2,
          gridStringOrNumberComparator
        ),
    },

    [TransactionsHeaders.description]: {
      field: TransactionsHeaders.description,
      flex: 1,
      minWidth: 220,
      renderCell: (
        params: GridRenderCellParams<{ entity: CreditTransaction }>
      ) => (
        <SphereTooltip
          title={
            ConsumptionDisplayReason[
              params.row.entity.action.data.consumptionReason ?? ""
            ]
          }
          boxProps={{
            sx: { ...withEllipsis },
          }}
        >
          {
            ConsumptionDisplayReason[
              params.row.entity.action.data.consumptionReason ?? ""
            ]
          }
        </SphereTooltip>
      ),
      valueGetter: (_, row: { entity: CreditTransaction }) =>
        ConsumptionDisplayReason[
          row.entity.action.data.consumptionReason ?? ""
        ],
      // eslint-disable-next-line @typescript-eslint/naming-convention -- name given by package
      sortable: true,
      sortComparator: (v1, v2, cp1, cp2) =>
        faroTableComparator<string>(
          v1,
          v2,
          cp1,
          cp2,
          gridStringOrNumberComparator
        ),
    },

    [TransactionsHeaders.project]: {
      field: TransactionsHeaders.project,
      flex: 1.3,
      minWidth: 220,
      renderCell: (
        params: GridRenderCellParams<{ entity: CreditTransaction }>
      ) => {
        return (
          <TransactionProject
            projectId={params.row.entity.context.projectId ?? undefined}
          />
        );
      },
      valueGetter: (_, row: { entity: CreditTransaction }) => {
        return projectsMap.get(row.entity.context.projectId || "")?.name;
      },
      // eslint-disable-next-line @typescript-eslint/naming-convention -- name given by package
      sortable: true,
      sortComparator: (v1, v2, cp1, cp2) =>
        faroTableComparator<string>(
          v1,
          v2,
          cp1,
          cp2,
          gridStringOrNumberComparator
        ),
    },

    [TransactionsHeaders.credits]: {
      field: TransactionsHeaders.credits,
      flex: 0.5,
      minWidth: 180,
      renderCell: (
        params: GridRenderCellParams<{ entity: CreditTransaction }>
      ) => {
        const isConsumed =
          params.row.entity.action.category ===
          TransactionActions.creditConsumption;

        return (
          <FaroChip
            label={
              <Box sx={{ fontSize: "10px", fontWeight: 700 }}>
                <var style={{ fontWeight: "inherit" }}>
                  {isConsumed ? "-" : "+"}
                  {}
                </var>
                {nounPluralize({
                  shouldShowCounter: true,
                  counter: params.row.entity.action.data.creditAmount,
                  word: "credit",
                })}
              </Box>
            }
            size="medium"
            variant="outlined"
            backgroundColor={
              isConsumed ? sphereColors.gray50 : sphereColors.green50
            }
            borderColor={
              isConsumed ? sphereColors.gray100 : sphereColors.green100
            }
          />
        );
      },
      valueGetter: (_, row: { entity: CreditTransaction }) => {
        // Return negative value if the transaction is related to consumption, otherwise positive value
        return row.entity.action.category ===
          TransactionActions.creditConsumption
          ? -Math.abs(row.entity.action.data.creditAmount)
          : Math.abs(row.entity.action.data.creditAmount);
      },
      // eslint-disable-next-line @typescript-eslint/naming-convention -- name given by package
      sortable: true,
      sortComparator: (v1, v2, cp1, cp2) =>
        faroTableComparator<string>(
          v1,
          v2,
          cp1,
          cp2,
          gridStringOrNumberComparator
        ),
    },
  };
}
