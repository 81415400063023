/** All the possible headers for transactions table. */
export enum TransactionsHeaders {
  /** The date of each transactions */
  date = "Date",

  /** User who used each of the transactions */
  user = "Users",

  /** Description of the transactions */
  description = "Description",

  /** Project from where the transactions created */
  project = "Project",

  /** Amount of credit information in each transactions */
  credits = "Credits",
}

/** All the possible headers for credit history table. */
export enum CreditPurchasesHistoryHeaders {
  /** The name of the package based on the credit amount */
  package = "Package",

  /** The date when the package was purchased */
  purchasedDate = "Purchased date",

  /** The expiration date of the package */
  expirationDate = "Expiration date",

  /** The status of the package */
  status = "Status",

  /** The remaining credits in the package */
  creditsRemaining = "Credits remaining",
}
