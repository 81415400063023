import { PageSectionProps } from "@components/page-section/page-section";
import { BaseSettingsPage } from "@pages/settings/base-settings-page";
import { Subscription } from "@custom-types/subscription-types";
import { useAppDispatch, useAppSelector } from "@store/store-helper";
import {
  isFetchingSdbCompanySelector,
  selectedSdbCompanyIdSelector,
  companyCreditBalanceSelector,
  getEarliestExpiringSubscriptionSelector,
  allCreditSubscriptionsSelector,
} from "@store/sdb-company/sdb-company-selector";
import { useDateTime } from "@hooks/use-date-time";
import {
  CONSTRAINT_LABEL,
  SUBSCRIPTION_CONSTRAINTS,
  SUBSCRIPTION_NAME,
} from "@src/constants/subscription-constants";
import { SubscriptionConstraint } from "@pages/settings/subscription-settings/subscription-constraint";
import { SubscriptionInfoItem } from "@pages/settings/subscription-settings/subscription-info-item";
import { SubscriptionInfoSubtitle } from "@pages/settings/subscription-settings/subscription-info-subtitle";
import { CreditsBalanceItem } from "@pages/settings/subscription-settings/credits/credits-balance-item";
import { AddCredits } from "@pages/settings/subscription-settings/credits/add-credits";
import { isAlphaTestingEnabledSelector } from "@store/app/app-selector";
import { useEffect } from "react";
import { CreditSubscriptions } from "@pages/settings/subscription-settings/credits/credit-subscriptions";
import { useCoreApiClient } from "@api/use-core-api-client";
import { fetchCompanySubscriptions } from "@store/sdb-company/sdb-company-slice";
import { CreditExpirationAlert } from "@pages/settings/subscription-settings/credits/credits-expiration-alert";
import { isSubscriptionNearExpiry } from "@utils/sdb-company-utils";
import { SphereDashboardAPITypes } from "@stellar/api-logic";
import { Grid, Skeleton } from "@mui/material";
import { NoCredits } from "@pages/settings/subscription-settings/credits/no-credits";

interface Props {
  /** Subscription entity */
  subscription: Subscription;
}

/**
 * Shows the subscription details
 */
export function SubscriptionSettings({ subscription }: Props): JSX.Element {
  const isLoading = useAppSelector(isFetchingSdbCompanySelector);
  const isAlphaTestingEnabled = useAppSelector(isAlphaTestingEnabledSelector);
  const selectedSdbCompanyId = useAppSelector(selectedSdbCompanyIdSelector);

  const { formatDate } = useDateTime();
  const coreApiClient = useCoreApiClient();
  const dispatch = useAppDispatch();
  const companyCreditBalance = useAppSelector(companyCreditBalanceSelector);
  const firstSubscriptionExpiringSoon = useAppSelector(getEarliestExpiringSubscriptionSelector);
  const isExpiringSoon = isSubscriptionNearExpiry(firstSubscriptionExpiringSoon);
  const creditSubscriptions =  useAppSelector(allCreditSubscriptionsSelector);
  const shouldShowNoCreditSection = isAlphaTestingEnabled && creditSubscriptions.length === 0;

  useEffect(() => {
    async function fetchCreditSubscriptionViaStore(): Promise<void> {
    /**
     * Fetch company subscriptions with status active, expired and invalid. subscriptions can have the following statuses:
     * Active - between start and end date
     * Expired - end date is in the past
     * Invalid - between start and end date but at least one constraint is over the limit and
     * thus the subscription is not providing the Features it contains.
     */
      const queryOptions = {
        status: [
          SphereDashboardAPITypes.SubscriptionStatus.active,
          SphereDashboardAPITypes.SubscriptionStatus.expired,
          SphereDashboardAPITypes.SubscriptionStatus.invalid,
        ],
      };
      await dispatch(
        fetchCompanySubscriptions({
          coreApiClient,
          queryOptions,
        })
      );
    }
    if (selectedSdbCompanyId) {
      void fetchCreditSubscriptionViaStore();
    }
  }, [coreApiClient, dispatch, selectedSdbCompanyId]);

  const pageSectionsProps: PageSectionProps[] = [
    {
      title: "Current plan",
      subtitle: (
        <SubscriptionInfoSubtitle name={SUBSCRIPTION_NAME[subscription.id]} />
      ),
      items: [
        {
          label: "Subscription plan",
          shouldAddRightPadding: true,
          content: (
            <SubscriptionInfoItem text={SUBSCRIPTION_NAME[subscription.id]} />
          ),
        },
        {
          label: "End date",
          shouldAddRightPadding: true,
          content: (
            <SubscriptionInfoItem
              text={formatDate(subscription.expiresAt)}
              shouldNotTranslate
            />
          ),
        },
      ],
    },
    {
      title: "Usage metrics",
      subtitle:
        // eslint-disable-next-line max-len
        "Your current plan has limits for certain features. This section outlines its usage. You can upgrade your plan at any time to adjust these limits.",
      isHidden: !SUBSCRIPTION_CONSTRAINTS[subscription.id].length,
      items: SUBSCRIPTION_CONSTRAINTS[subscription.id].map(
        (constraint, index) => {
          return {
            label: CONSTRAINT_LABEL[constraint.type],
            shouldAddRightPadding: true,
            content: (
              <SubscriptionConstraint key={index} constraint={constraint} />
            ),
          };
        }
      ),
    },
    {
      title: "Credits",
      subtitle: `Use and share credits with your team to generate floor plans within your projects. 
      Explore the different credit bundles we offer.`,
      isHidden: !isAlphaTestingEnabled || creditSubscriptions.length === 0,
      footerAction: <AddCredits />,
      // TODO: Add tooltip content here. It will be added in this PR.
      // tooltipInfo: (
      //   <Typography color={sphereColors.black}>mock tooltip</Typography>
      // ),
      items: [
        {
          label: "Balance",
          content: (
            <CreditsBalanceItem
              creditBalance={companyCreditBalance.toString()}
            />
          ),
        },
        {
          label: firstSubscriptionExpiringSoon
            ? `${firstSubscriptionExpiringSoon.availableCredit} credits expire on`
            : "No credits available",
          content: (
            <CreditSubscriptions
              firstSubscriptionExpiringSoon={firstSubscriptionExpiringSoon}
              isExpiringSoon={isExpiringSoon}
            />
          ),
          shouldHideBottomBorder: isExpiringSoon,
        },
        {
          content: <CreditExpirationAlert />,
          shouldHideBottomBorder: true,
          shouldContentTakeFullWidth: true,
          isHidden: !isExpiringSoon,
        },
      ],
    },
  ];

  return (
    <>
      <BaseSettingsPage
        pageSectionProps={pageSectionsProps}
        isLoading={isLoading}
        dataTestId="workspace-settings-subscription"
        customSx={{ mt: "45px" }}
    />
      {(isLoading || shouldShowNoCreditSection) && (
        <Grid mt="45px">
          {isLoading ? (
            <Skeleton
              variant="rectangular"
              sx={{ width: "100%", height: "350px" }}
            />
          ) : (
            shouldShowNoCreditSection && <NoCredits />
          )}
        </Grid>
      )}
    </>
  );
}
