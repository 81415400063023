import { Stack, Typography } from "@mui/material";
import { AddCredits } from "@pages/settings/subscription-settings/credits/add-credits";
import { sphereColors } from "@styles/common-colors";
import { DEFAULT_TEXT_FONT_SIZE, DEFAULT_TITLE_FONT_SIZE } from "@styles/common-styles";
import NoCreditIcon from "@assets/icons/new/no-credit.svg?react";

/**
 * The component to show when the user doesn't have any credits and never had any.
*/
export function NoCredits(): JSX.Element {
  return (
    <Stack
      sx={{
        alignItems: "center",
        width: "100%",
        gap: "12px",
        paddingBottom: "32px",
        backgroundColor: sphereColors.gray50,
      }}
    >
      <NoCreditIcon />
      <Typography
        sx={{
          fontSize: DEFAULT_TITLE_FONT_SIZE,
          fontWeight: 600,
          lineHeight: "24px",
          color: sphereColors.gray600,
        }}
      >
        You don't have any credits yet.
      </Typography>
      <Stack  
        sx={{
          alignItems: "center",
          gap: "32px",
        }}
      >
        <Typography
          sx={{
            fontSize: DEFAULT_TEXT_FONT_SIZE,
            color: sphereColors.gray600,
            textAlign: "center",
          }}
        >
          Credits allow you and your team to generate floor plans within your
          projects. You can add credits anytime to start using them.
        </Typography>
        <AddCredits />
      </Stack>
    </Stack>
  );
}
